import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import CategoryForm from "components/Products/Categories/CategoryForm";
import { createCategory } from "services/ProductsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function CreateCatrgory() {
  const [fields, setFields] = useState({});
  const [image, setImage] = useState();
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);
  const [faq, setFaq] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  useEffect(() => {
    setPageTitle("Create Product Category - Inspire Uplift");
  }, []);

  const history = useNavigate();

  const handleEditor = event => {
    const { name, value } = event;
    if (name === "description") {
      setDescription(value);
    }
  };
  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target?.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleSubmit = e => {
    const filteredFaq = faq?.filter(
      item => item["question"] !== "" && item["answer"] !== ""
    );
    e.preventDefault();
    const data = {
      ...fields,
      parent_id: fields?.type === "sub_category" ? fields?.parent_id : 0,
      description: description || undefined,
      image: image || undefined,
      faq: filteredFaq,
      is_hidden: fields?.is_hidden ? 1 : 0,
    };
    toast.dismiss();
    setLoading(true);
    createCategory(data)
      .then(res => {
        history(-1);
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array?.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  const handleAddQuestion = () => {
    const newFAQ = {
      question: "",
      answer: "",
    };
    setFaq([...faq, newFAQ]);
  };

  const handleDelete = itemIndex => {
    if (faq.length > 1) {
      const filterFAQ = faq.filter((item, index) => index !== itemIndex);
      setFaq(filterFAQ);
    }
  };

  const handleFaq = (e, index) => {
    let faqObj = [...faq];
    if (e.name == "answer") {
      faqObj[index][e.name] = e.value;
    } else {
      let name = e.target.name;
      let value = e.target.value;
      faqObj[index][name] = value;
    }
    setFaq(faqObj);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <TopHeading heading={"Create Product Categories"} />
          <div className="d-flex align-items-center">
            <Button
              className="me-3"
              onClick={() => history("/products/categories")}
            >
              Cancel
            </Button>
            <Button color="primary">Create</Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <CategoryForm
              fields={fields}
              handleAddQuestion={handleAddQuestion}
              faq={faq}
              handleChange={handleChange}
              description={description}
              image={image}
              handleEditor={handleEditor}
              setImage={setImage}
              handleDelete={handleDelete}
              handleFaq={handleFaq}
              setLoading={setLoading}
              setFields={setFields}
            />
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default CreateCatrgory;
