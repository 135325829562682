import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import QuantityInput from "../QuantityInput";
import { getRefundData, createRefund } from "services/OrderManagementService";
import ProgressPopup from "./ProgressPopup";
import PrimaryToast from "../Toast/ToastMessage";
import { useDispatch } from "react-redux";
import { addInHistory } from "store/actions/orders";
import { substractVariantTitle } from "utils/string";
import moment from "moment";

const RefundOrderPopup = ({ handleClick, orderNo, historyId, userName }) => {
  const [isPartial, setIsPartial] = useState(false);
  const [refundsData, setRefundsData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [actualTotal, setActualTotal] = useState("");
  const [shippingCheckboxStates, setShippingCheckboxStates] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getRefundData(orderNo)
      .then(res => {
        setRefundsData(res?.data);
        setActualTotal(res?.data?.total_price);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  const handleShippingCheckboxChange = (
    index,
    sellerName,
    itemId,
    selectedItem,
    isChecked
  ) => {
    setShippingCheckboxStates(prevStates => ({
      ...prevStates,
      [`seller_${itemId}_${index}`]: isChecked,
    }));
    handleChange(selectedItem, selectedItem?.quantity, sellerName, isChecked);
  };

  const handleChange = (item, qtn, sellerName, isShiping) => {
    let id = item.id;
    let newData = { ...refundsData };
    let updatedLineItems = refundsData?.line_items[sellerName]?.line_items.map(
      item => {
        if (item.id === id) {
          const tax = (qtn * item?.taxCalculated) / item?.fix_quantity;
          const formattedTax = tax.toFixed(2);
          const shippingRate =
            isShiping && qtn > 0
              ? item?.shipping_quantity < qtn
                ? item?.shipping * item?.shipping_quantity
                : item?.shipping * qtn
              : 0;
          const formattedShippingRate = shippingRate.toFixed(2);
          const amount =
            item.price * qtn + tax - item?.discount * qtn + shippingRate;
          const formattedAmount = amount.toFixed(2);
          return {
            ...item,
            quantity: qtn,
            subtotal: formattedAmount,
            tax: formattedTax,
            shippingRate: formattedShippingRate,
            refund_shipping: isShiping,
          };
        } else {
          return item;
        }
      }
    );
    newData.line_items[sellerName].line_items = updatedLineItems;
    const totalAmount = Object.values(refundsData.line_items).reduce(
      (total, seller) => {
        const sellerTotal = seller.line_items.reduce(
          (sellerTotal, lineItem) => {
            if (lineItem && lineItem.subtotal) {
              return sellerTotal + parseFloat(lineItem.subtotal);
            } else {
              return sellerTotal;
            }
          },
          0
        );
        return total + sellerTotal;
      },
      0
    );

    const formattedTotalAmount = totalAmount.toFixed(2); // format the totalAmount value with 2 decimal places
    setGrandTotal(formattedTotalAmount);
    setRefundsData({
      ...newData,
      subTotal: formattedTotalAmount,
    });
  };

  const handleCreateRefund = e => {
    const data = {
      line_items: refundsData.line_items,
      order_id: refundsData?.id,
      subTotal: grandTotal,
      partial: isPartial,
      actualSubTotal: actualTotal,
      paidByCustomer: 0,
      ordersGiftCardRefund: 0,
      giftCardAmount: 0,
      orderWalletAmount: 0,
    };
    setLoading(true);
    createRefund(data)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        handleClick(e, "refund");
        let historyData = {
          id: historyId + 1,
          comment: `Refund Created. Amount: ${grandTotal}`,
          created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
          admin: userName,
        };
        dispatch(addInHistory(historyData));
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.error)
            ? err?.response?.data?.error[0]
            : err?.response?.data?.error,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="order-refund-popup-body">
        <p className="mb-3 font-semi-bold text-right">
          Order Date: {refundsData?.orderDate}
        </p>
        <div className="order-refund-popup-table-header">
          <div style={{ width: "50px" }}>#</div>
          <div style={{ width: "300px" }}>Product</div>
          <div style={{ width: "100px" }}>Quantity</div>
          <div style={{ width: "90px" }}>Price</div>
          <div style={{ width: "90px" }}>Discount</div>
          <div style={{ width: "90px" }}>Tax</div>
          <div style={{ width: "90px" }}>Shipping</div>
          <div style={{ width: "80px" }}>Refund</div>
          <div style={{ width: "110px" }}>Total Amount</div>
        </div>
        <div className="">
          {refundsData?.line_items &&
            Object.keys(refundsData?.line_items).length > 0 &&
            Object.keys(refundsData?.line_items).map((item, index) => {
              const lineItem = refundsData?.line_items[item];
              return (
                <div className="" key={index}>
                  <div className="d-flex align-items-center justify-content-between py-3 px-2 border-bottom">
                    <div className="font-semi-bold h5 mb-0 order-refund-seller-name">
                      {item}
                    </div>
                    <div className="d-flex align-items-center font-semi-bold">
                      <div>
                        <span>Commission Fee:</span>{" "}
                        <span className="ps-2">
                          ${lineItem?.commission?.toFixed(2)}
                        </span>
                      </div>
                      <div className="ms-4">
                        <span>Processing Fee:</span>{" "}
                        <span className="ps-2">
                          ${lineItem?.processing_fee?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  {lineItem?.line_items?.map((item1, index1) => {
                    const itemId = item1.id;
                    const shippingCheckboxKey = `seller_${itemId}_${index1}`;
                    const isShippingChecked =
                      shippingCheckboxStates[shippingCheckboxKey] || false;

                    return (
                      <div
                        className="d-flex align-items-center justify-content-between py-3 px-2 border-bottom"
                        key={index1}
                      >
                        <div style={{ width: "50px", fontWeight: "600" }}>
                          {index1 + 1}
                        </div>
                        <div style={{ width: "300px" }}>
                          {item1?.name}{" "}
                          {item1?.type == "configurable" &&
                            `- ${substractVariantTitle(
                              item1.name,
                              item1?.variant_title
                            )}`}
                        </div>
                        <div className="me-2">
                          <QuantityInput
                            totalQuantity={item1?.fix_quantity}
                            handleChange={handleChange}
                            item={item1}
                            sellerName={item}
                            isShippingCheck={isShippingChecked}
                          />
                        </div>
                        <div style={{ width: "90px" }}>
                          ${item1?.price?.toFixed(2)}
                        </div>
                        <div style={{ width: "90px" }}>
                          ${(item1?.quantity * item1.discount)?.toFixed(2)}
                        </div>
                        <div style={{ width: "90px" }}>
                          ${item1?.tax ? item1?.tax : 0}
                        </div>
                        <div className="form-check" style={{ width: "90px" }}>
                          {item1?.shipping_quantity > 0 && (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`experide-shipping-${index1}`}
                              checked={isShippingChecked}
                              disabled={item1?.fix_quantity == 0}
                              onChange={e =>
                                handleShippingCheckboxChange(
                                  index1,
                                  item,
                                  itemId,
                                  item1,
                                  e.target.checked
                                )
                              }
                            />
                          )}
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckIndeterminate"
                          >
                            ${item1?.shipping}
                          </label>
                        </div>
                        <div style={{ width: "90px" }}>
                          ${item1?.refund_subtotal?.toFixed(2)}
                        </div>
                        <div style={{ width: "110px" }}>
                          {item1?.subtotal && `$${item1?.subtotal}`}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          <div className="refund-order-summary-section-container my-4 d-flex justify-content-end border-bottom">
            <div className="refund-order-summary-section-inner-wrapper">
              <div className="font-semi-bold h5 mb-3">Refund Totals</div>
              <div
                className={`d-flex justify-content-between py-4 border-top border-bottom ${
                  isPartial && "align-items-center"
                }`}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    onChange={e => setIsPartial(e.target.checked)}
                    id="partialRefund"
                  />
                  <label className="form-check-label" htmlFor="partialRefund">
                    Partial Refund
                  </label>
                </div>
                {isPartial && (
                  <input
                    type="text"
                    className="p-2 w-25"
                    onChange={e => {
                      if (e.target.value > parseFloat(refundsData.subTotal)) {
                        return false;
                      } else {
                        setGrandTotal(e.target.value);
                      }
                    }}
                    value={grandTotal}
                  />
                )}
              </div>
              <div className="my-3 d-flex justify-content-between align-items-center">
                <div className="font-semi-bold h5">Grand Total</div>
                <div className="font-semi-bold h5">
                  {grandTotal && `$${grandTotal}`}
                </div>
              </div>
            </div>
          </div>
          <div className="order-summary-action-section d-flex justify-content-end">
            <div className="d-flex align-items-center">
              <Button
                color="primary"
                className="me-4"
                onClick={handleCreateRefund}
                disabled={loading}
              >
                Save
              </Button>
              <Button className="secondary" onClick={handleClick}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundOrderPopup;
