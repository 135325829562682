import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Container, Card, CardBody, Button } from "reactstrap";
import tickIcon from "../../assets/images/tick-icon.svg";
import HoldOrderPopup from "components/Common/Popups/HoldOrderPopup";
import {
  getOrderNotifications,
  createOrderSuspicious,
} from "services/NotificationService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimarySearch from "components/Common/PrimarySearch";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { useNavigate } from "react-router-dom";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import DateRangePicker from "components/Common/DateRangePicker";
import moment from "moment";

const OrdersNotifications = () => {
  const [fruadOrdersData, setFruadOrdersData] = useState([]);
  const [isSuspicious, setSuspicious] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [status, setStatus] = useState();
  const [searchVal, setSearch] = useState(null);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const paramsLength = Array.from(params.keys()).length;
  const history = useNavigate();

  useEffect(() => {
    const status = params.get("type");
    const page = params.get("page") || undefined;
    const searchParam = params.get("search") || "";
    const startDateParam = params?.get("start") || undefined;
    const endDateParam = params?.get("end") || undefined;
    setSearch(searchParam);
    setPageNo(page || 1);
    setStatus(status);
    setLoading(true);
    if(startDateParam){
      setDates([
        {
          key:"selection",
          startDate:new Date(startDateParam),
          endDate:new Date(endDateParam)
        }
      ])
    }
    fraudOrdersNotifications(page, searchParam, status,startDateParam, endDateParam);
  }, [window?.location?.search]);

  useEffect(() => {
    setPageTitle("Order Notifications - Inspire Uplift");
  }, []);

  const fraudOrdersNotifications = (page, search, status,start,end) => {
    getOrderNotifications(page,start,end, search, status)
      .then(res => {
        if (res?.data?.fraudOrders?.data?.length == 0 && page > 1) {
          params?.set("page", page - 1);
          const newUrl = `${window.location.pathname}?${params.toString()}`;
          history(newUrl);
        } else {
          setFruadOrdersData(res?.data?.fraudOrders?.data);
          setPageCount(res?.data?.fraudOrders?.last_page);
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "message",
      text: "Notification",
      style: {
        maxWidth: "500px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
        overFlow: "hidden",
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell, row) => <div>{row.created_at}</div>,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div>
            {row.is_suspicious === 1 ? (
              <div>Marked Suspicious by {row?.admin}</div>
            ) : (
              <Button
                color="primary"
                onClick={e => handleSuspicious(e, row.id)}
              >
                <img src={tickIcon} alt="tick icon" />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleSuspicious = (e, id) => {
    if (e.target.id === "close-popup" || e.target?.id === "cancle-action") {
      setSelectedId();
    } else {
      setSelectedId(id);
    }
    setSuspicious(!isSuspicious);
  };

  const handleCreateSuspicious = () => {
    toast.dismiss();
    setLoading(true);
    createOrderSuspicious(selectedId)
      .then(res => {
        if (res.status) {
          PrimaryToast({ type: "success", message: res?.message });
          setSelectedId();
          setSuspicious(!isSuspicious);
          const startDate = params?.get('start') ? moment(dates[0]?.startDate).format("YYYY-MM-DD")  :undefined;
          const endDate = params?.get('end') ?  moment(dates[0]?.endDate).format("YYYY-MM-DD")  : undefined;
          fraudOrdersNotifications(pageNo, searchVal, status,startDate ,endDate);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const data = fruadOrdersData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSearch = e => setSearch(e.target.value);

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (searchVal) {
        params?.set("search", searchVal);
      }
      if (!searchVal && params?.get("search")) {
        params?.delete("search");
      }
      if (params?.get("page")) {
        params?.delete("page");
      }
      history(`?${params?.toString()}`);
    }
  };

  const handleFilter = () => {
    if (status) {
      params?.set("type", status);
    } else {
      params.delete("type");
    }
    if(searchVal){
      params?.set("search", searchVal);
    }
    else {
      params?.delete("search");
    }
    params.delete("page");
    params?.set('start',moment(dates[0]?.startDate).format("YYYY-MM-DD"));
    params?.set('end',moment(dates[0]?.endDate).format("YYYY-MM-DD"));
    // Update the URL with the new search parameters
    history(`?${params.toString()}`);
  };

  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  const handleSubmit = () => {
    setShowCalendar(false);
    params?.set('start',moment(dates[0]?.startDate).format("YYYY-MM-DD"));
    params?.set('end',moment(dates[0]?.endDate).format("YYYY-MM-DD"));
    history(`?${params?.toString()}`);
  }

  const handleReset = () => {
    setStatus("");
    setDates([
      {
        key:"selection",
        startDate:new Date(),
        endDate:new Date()
      }
    ])
    setSearch("");
    params.delete("search");
    params.delete("page");
    params.delete("type");
    params?.delete("start");
    params?.delete("end");
    history({ search: params.toString(), replace: true });
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <Container fluid>
        <TopHeading heading={"Order Notifications"} />
        <div className="mt-4">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <PrimarySearch
                  placeholder={"Search by email,order number"}
                  handleChange={handleSearch}
                  handleKeyPress={handleKeyPress}
                  value={searchVal}
                />
                <div className="d-flex flex-wrap ms-3">
                  <select
                    className="primary-select-option"
                    value={status || ""}
                    onChange={e => {
                      setStatus(e.target.value);
                    }}
                    onKeyPress={handleFilter}
                  >
                    <option value={""}>Search by</option>
                    <option value={"order"}>Order</option>
                    <option value={"same_ip_order"}>Same Ip Order</option>
                    <option value={"card_vault"}>Card Vault</option>

                  </select>
                  <div className="position-relative ms-3">
                    <div
                      className="form-control cursor-pointer rounded-full"
                      style={{ color: "#74788D", width: "max-content" }}
                      onClick={() => setShowCalendar(true)}
                    >
                      {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                      {moment(dates[0].endDate).format("DD MMM YYYY")}
                    </div>

                    {showCalendar && (
                      <>
                        <div
                          className="position-fixed top-0 start-0 w-100 h-100"
                          style={{ zIndex: "10" }}
                          onClick={() => setShowCalendar(false)}
                        ></div>
                        <DateRangePicker
                          dates={dates}
                          handleDatePicker={handleDatePicker}
                          handleSubmit={handleSubmit}
                          maxDate={new Date()}
                        />
                      </>
                    )}
                  </div>
                  <Button
                    color="primary"
                    className={`btn-rounded  ms-3`}
                    onClick={handleFilter}
                  >
                    Apply Filter
                  </Button>
                  {paramsLength > 0 && (
                    <Button
                      color="secondary"
                      className={`btn-rounded  ms-3`}
                      onClick={handleReset}
                    >
                      Reset Filter
                    </Button>
                  )}
                </div>
              </div>

              <div className="mt-4 table-responsive">
                <PrimaryTable
                  columns={columns}
                  data={data}
                  bordered={false}
                  keyField="id"
                />
              </div>
              {fruadOrdersData?.length > 0 && pageCount > 1 && (
                <PrimaryPagination
                  pageNo={pageNo}
                  pageCount={pageCount}
                  setPageNo={setPageNo}
                />
              )}
            </CardBody>
          </Card>
        </div>
        {isSuspicious && (
          <HoldOrderPopup
            isOpen={isSuspicious}
            heading={"Notification"}
            isScrollable={false}
            handleClick={handleSuspicious}
            handleYes={handleCreateSuspicious}
            description={
              "Are you  sure you want to make this notification suspicious?"
            }
          />
        )}
      </Container>
    </div>
  );
};

export default OrdersNotifications;
