import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import TopHeading from "components/Common/TopHeading";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import deleteIcon from "../../assets/images/delete-icon.svg";

import AddressComponent from "components/Orders/Address";
import OrderDetailsComponent from "components/Orders/OrderDetail";
import PaymentDetailComponent from "components/Orders/PaymentDetail";
import OrderDetailTable from "components/Orders/OrderDetailTable";
import ProductDetail from "components/Orders/ProductDetail";
import WarningOrder from "components/Orders/WarningOrder";
import ClientDetailsComponent from "components/Orders/ClientDetails";
import useToggle from "hooks/useToggle";
// popups
import PrimaryPopup from "components/Common/Popups/PrimaryPopup";
import RefundOrderPopup from "components/Common/Popups/RefundOrderPopup";
import AddTrackingPopup from "components/Common/Popups/AddTrackingPopup";
import HoldOrderPopup from "components/Common/Popups/HoldOrderPopup";
import EditAddressPopup from "components/Common/Popups/EditAddressPopup";
import ReactToPrint from "react-to-print";
import { loadHistory, addInHistory } from "store/actions/orders";
import { useSelector, useDispatch } from "react-redux";

import {
  getOrderDetail,
  messageCreate,
  updateShippingAddress,
  updateBillingAddress,
  createTracking,
  holdOrder,
  approveOrderPayment,
  updateEmail,
  getAllAdditionalMesssaegs,
  messageDelete,
  deleteTracking,
  orderDetailHistories,
  getDigitalFiles,
  updateTracking,
} from "services/OrderManagementService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import EmailUpdatePopup from "components/Common/Popups/EmailUpdatePopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import DeleteModal from "components/Common/Toast/DeleteModal";
import DeleteTrackingPopup from "components/Common/Popups/DeleteTrackingPopup";
import InvoicePrint from "components/Orders/PrintSlip";
import { getTrackingNumber } from "store/actions/orders";
import moment from "moment";
import { toast } from "react-toastify";
import DownloadDigalFile from "components/Common/Popups/DownloadDigitalFile";
import { setPageTitle } from "utils/pageTitle";
import { substractVariantTitle } from "utils/string";
const AddMessageComponent = lazy(() => import("components/Orders/AddMessage"));
const HistoryComponet = lazy(() => import("components/Orders/HistoryComponet"));

const OrderDetail = () => {
  const [isRefundOrder, setRefundOrder] = useToggle(false);
  const [isAddTracking, setAddTracking] = useToggle(false);
  const [isHoldOrder, setHolderOrder] = useToggle(false);
  const [isApprovePayment, setApprovePayment] = useState(false);
  const [isEditShippingAddress, setEditShippingAddress] = useToggle(false);
  const [isEditBillingAddress, setEditBillingAddress] = useToggle(false);
  const [loading, setLoading] = useState(false);
  const [orderData, setOrdersData] = useState({});
  const [message, setMessage] = useState("");
  const [fields, setFields] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    province: "",
    country_code: "",
    province_code: "",
    zip: "",
  });
  const [additionalMessages, setAdditionalMessages] = useState([]);
  const [messagePageCount, setMessagePageCount] = useState();
  const [messagePage, setMessagePage] = useState(1);
  const [sellerId, setSellerId] = useState();
  const [email, setEmail] = useState("");
  const [isEmailUpdate, setIsEmailUpdate] = useToggle(false);
  const [deleteMessageId, setDeleteMessageId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [orderHistoryPage, setOrderHistoryPage] = useState(1);
  const [orderHistoryPageCount, setOrderHistoryPageCount] = useState();
  const [fulfillmentId, setFulfilmentIds] = useState();
  const [isDeleteTracking, setIsDeleteTracking] = useToggle(false);
  const [isOpeFiles, setIsOpeFiles] = useToggle(false);
  const [isProvinceText, setIsProvinceText] = useState(false);
  const [digitelsFiles, setDigitalFiles] = useState([]);

  const invoiceRef = useRef();
  const params = useParams();

  const dispatch = useDispatch();
  const ordersHistory = useSelector(
    state => state?.ordersReducer?.orderHistories
  );
  const userInfo = useSelector(state => state?.auth?.user);
  const orderTrackingNumber = useSelector(
    state => state?.ordersReducer?.tracking_numbers
  );
  const hasFinancialPermission = userInfo?.permissions?.some(
    item => item.name === "financial-details"
  );
  const permissions = userInfo?.permissions?.map(item => item.name);
  const countires = useSelector(state => state?.countiresReducer?.countires);
  const statesData = useSelector(state => state?.statesReducer?.states);

  useEffect(() => {
    setPageTitle(`Order #${params?.orderNumber} - Inspire Uplift`);
  }, []);

  useEffect(() => {
    setLoading(true);
    getOrderDetail(params?.orderNumber)
      .then(res => {
        setOrdersData(res?.data);
        setEmail(res?.data?.order_detail?.customer_email);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    orderDetailHistories(params?.orderNumber, orderHistoryPage)
      .then(res => {
        dispatch(loadHistory(res.data?.data));

        setOrderHistoryPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [orderHistoryPage]);

  useEffect(() => {
    getAllAdditionalMesssaegs(params?.orderNumber, messagePage).then(res => {
      setAdditionalMessages(res?.data?.data);
      setMessagePageCount(res?.data?.last_page);
    });
  }, [messagePage]);

  const refundColumns = [
    {
      dataField: "index",
      text: "#",
    },
    {
      dataField: "product",
      text: "Product",
      formatter: (cell, row) => (
        <div style={{ width: "338px", whiteSpace: "break-spaces" }}>
          {row?.product}
        </div>
      ),
    },
    {
      dataField: "variant",
      text: "Variant",
      formatter: (cell, row) => (
        <div style={{ width: "220px", whiteSpace: "break-spaces" }}>
          {row?.product_type == "configurable" ? substractVariantTitle(row?.title, row?.variant ) || '-' : null}
        </div>
      ),
    },

    {
      dataField: "qty",
      text: "Quantity",
    },
    {
      dataField: "price",
      text: "Price",
    },
  ];

  const messageColumns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => <div>{(messagePage - 1) * 10 + row.index}</div>,
    },
    {
      dataField: "comment",
      text: "Comment",
      style: {
        width: "250px",
        "white-space": "break-spaces",
      },
    },
    {
      dataField: "admin",
      text: "From",
    },
    {
      dataField: "created_at",
      text: "Date",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          permissions?.includes("comment-delete") ? (
            <img
              src={deleteIcon}
              alt="delte icon "
              className="cursor-pointer "
              height={20}
              width={20}
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteMessageId(row?.id);
              }}
            />
          ) : null
        );
      },
    },
  ];

  const messagesData = additionalMessages?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleRefundOrderPopup = (e, type) => {
    setRefundOrder(prevState => !prevState);
    removeBodyCss();
    if (type) {
      setLoading(true);
      getOrderDetail(params?.orderNumber)
        .then(res => {
          setOrdersData(res?.data);
          setEmail(res?.data?.order_detail?.customer_email);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(res => {
          setLoading(false);
        });
    }
  };

  const handleAddTrackingPopup = () => {
    setAddTracking(!isAddTracking);
    removeBodyCss();
  };

  const handleUpdateTracking = () => {
    setIsDeleteTracking(!isDeleteTracking);
    removeBodyCss();
  };

  const handleHoldOrder = () => {
    setHolderOrder(!isHoldOrder);
    removeBodyCss();
  };

  const handleEmail = () => {
    setIsEmailUpdate(!isEmailUpdate);
    removeBodyCss();
  };

  const handleEmailUpdate = () => {
    toast.dismiss();
    setLoading(true);
    updateEmail(params?.orderNumber, email)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setIsEmailUpdate(false);
        let historyData = {
          id: ordersHistory[0]?.id + 1,
          comment: "Customer email updated.",
          created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
          admin: userInfo?.name,
        };
        dispatch(addInHistory(historyData));
        setOrdersData({
          ...orderData,
          order_detail: {
            ...orderData?.order_detail,
            customer_email: email,
          },
        });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleEditAddress = type => {
    if (type === "shipping") {
      setEditShippingAddress(!isEditShippingAddress);
    } else {
      setEditBillingAddress(!isEditBillingAddress);
    }
    removeBodyCss();
  };

  const handleAddMessage = () => {
    const data = {
      comment: message,
      order_id: params?.orderNumber,
    };
    toast.dismiss();
    setLoading(true);
    messageCreate(data)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setMessage("");
        getAllAdditionalMesssaegs(params?.orderNumber, messagePage).then(
          res => {
            setAdditionalMessages(res?.data?.data);
            setMessagePageCount(res?.data?.last_page);
            setLoading(false);
          }
        );
      })
      .catch(err => {
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
        console.log(err);
      });
  };

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target?.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    if (name === "country_code") {
      if (value !== "US") {
        setIsProvinceText(true);
        fieldsObj["province_code"] = "";
        fieldsObj["province"] = "";
      } else {
        setIsProvinceText(false);
      }
    }

    setFields(fieldsObj);
  };

  const handleUpdateTrackingNumber = data => {
    toast.dismiss();
    setLoading(true);
    updateTracking(data)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: "Tracking update successfully",
        });
        setFulfilmentIds();
        setIsDeleteTracking(false);
        let historyData = {
          id: ordersHistory[0]?.id + 1,
          status: "Tracking update successfully.",
          created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
          admin: userInfo?.name,
          tracking_number: [
            `${data?.aliExpressNumbers}`,
            `${data?.tracking ? `${data?.tracking}` : ""}`,
          ],
        };
        dispatch(addInHistory(historyData));
        dispatch(getTrackingNumber(""));
        getOrderDetail(params?.orderNumber).then(res => {
          setOrdersData(res?.data);
          setEmail(res?.data?.order_detail?.customer_email);
        });
      })
      .catch(err => {
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleSubmit = (e, type) => {
    e.preventDefault();
    toast.dismiss();
    let countryData = countires?.find(
      item => item.alpha2Code == fields?.country_code
    );
    let states = statesData?.find(
      item => item.abbreviation == fields?.province_code
    );
    let data = {
      ...fields,
      country: countryData?.name,
      province_code: isProvinceText ? fields?.province : fields?.province_code,
      province: isProvinceText ? fields?.province : states?.name,
    };
    setLoading(true);
    if (type === "shipping") {
      updateShippingAddress(data, params?.orderNumber)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          setEditShippingAddress(false);
          setFields({
            first_name: "",
            last_name: "",
            phone: "",
            address1: "",
            address2: "",
            country: "",
            city: "",
            province: "",
            country_code: "",
            province_code: "",
            zip: "",
          });
          let historyData = {
            id: ordersHistory[0]?.id + 1,
            comment: "Shipping address updated.",
            created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
            admin: userInfo?.name,
          };
          setOrdersData({
            ...orderData,
            shipping_address: {
              ...data,
              address: fields?.address1,
              address1: fields?.address2,
			  name:`${fields?.first_name} ${fields?.last_name}`,
            },
          });
          setLoading(false);
          dispatch(addInHistory(historyData));
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
        });
    } else if (type === "billing") {
      updateBillingAddress(data, params?.orderNumber)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          setEditBillingAddress(false);
          setFields({
            first_name: "",
            last_name: "",
            phone: "",
            address1: "",
            address2: "",
            country: "",
            city: "",
            province: "",
            country_code: "",
            province_code: "",
            zip: "",
          });
          let historyData = {
            id: ordersHistory[0]?.id + 1,
            comment: "Billing address updated.",
            created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
            admin: userInfo?.name,
          };
          setOrdersData({
            ...orderData,
            billing_address: {
              ...data,
              address: fields?.address1,
              address1: fields?.address2,
			  name:`${fields?.first_name} ${fields?.last_name}`,
            },
          });

          dispatch(addInHistory(historyData));
          setLoading(false);
        })

        .catch(err => {
          setLoading(false);
          console.log(err);
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
        });
    }
  };

  const handleCreateTracking = (
    quantityData,
    trackingNumber,
    aliExpressNumbers
  ) => {
    let data = {
      tracking: trackingNumber,
      aliExpressNumbers: aliExpressNumbers,
      ...quantityData,
    };
    toast.dismiss();
    setLoading(true);
    createTracking(data, params?.orderNumber)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: "Tracking number saved successfully.",
        });
        setAddTracking(false);
        let historyData = {
          id: ordersHistory[0]?.id + 1,
          status:
            aliExpressNumbers && trackingNumber
              ? "Tracking created by tracking number & ali express."
              : trackingNumber && !aliExpressNumbers
              ? "Tracking created by tracking number."
              : !trackingNumber && aliExpressNumbers
              ? "Tracking created by ali express."
              : "",
          created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
          admin: userInfo?.name,
          tracking_number:
            trackingNumber && !aliExpressNumbers
              ? [trackingNumber]
              : !trackingNumber && aliExpressNumbers
              ? [aliExpressNumbers]
              : trackingNumber && aliExpressNumbers
              ? [trackingNumber, aliExpressNumbers]
              : "",
        };
        dispatch(addInHistory(historyData));
        getOrderDetail(params?.orderNumber)
          .then(res => {
            setOrdersData(res?.data);
            setEmail(res?.data?.order_detail?.customer_email);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(res => setLoading(false));
      })
      .catch(err => {
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
        console.log(err);
      });
  };

  const handleOrderHold = () => {
    let holdStatus = orderData?.order_status !== "Hold" ? 1 : 2;
    toast.dismiss();
    setLoading(true);
    holdOrder(params?.orderNumber, holdStatus)
      .then(res => {
        setHolderOrder(false);
        PrimaryToast({ type: "success", message: res?.data?.message });
        setOrdersData({
          ...orderData,
          order_status:
            orderData?.order_status == "Processed" ? "Hold" : "Processed",
        });
        let historyData = {
          id: ordersHistory[0]?.id + 1,
          comment: holdStatus == 1 ? "Hold" : holdStatus === 2 ? "UnHold" : "",
          created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
          admin: userInfo?.name,
        };
        dispatch(addInHistory(historyData));
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      });
  };

  const approvePayment = () => {
    toast.dismiss();
    setLoading(true);
    approveOrderPayment(params?.orderNumber, sellerId)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setApprovePayment(false);
        setSellerId("");
        getOrderDetail(params?.orderNumber)
          .then(res => {
            setOrdersData(res?.data);
            let historyData = {
              id: ordersHistory[0]?.id + 1,
              comment: "Order payment approved.",
              created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
              admin: userInfo?.name,
            };
            dispatch(addInHistory(historyData));
          })
          .catch(err => {
            console.log(err);
          })
          .finally(res => {
            setLoading(false);
          });
      })
      .catch(err => {
        setLoading(false);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      });
  };

  const handleDelete = () => {
    const filterArr = additionalMessages?.filter(
      item => item.id !== deleteMessageId
    );
    toast.dismiss();
    setLoading(true);
    messageDelete(deleteMessageId)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.message });
        setDeleteMessageId();
        setShowDeleteModal(false);
        setAdditionalMessages(filterArr);
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleDeleteTracKing = () => {
    toast.dismiss();
    setLoading(true);
    deleteTracking(fulfillmentId)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res.data.message,
        });
        setFulfilmentIds();
        setIsDeleteTracking(false);
        let historyData = {
          id: ordersHistory[0]?.id + 1,
          status: "Tracking deleted successfully.",
          created_at: moment(new Date()).format("DD-MM-YYYY HH:mm:ss A"),
          admin: userInfo?.name,
          tracking_number: orderTrackingNumber,
        };
        dispatch(addInHistory(historyData));
        dispatch(getTrackingNumber(""));
        getOrderDetail(params?.orderNumber)
          .then(res => {
            setOrdersData(res?.data);
            setEmail(res?.data?.order_detail?.customer_email);
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: err?.response.data.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleUpdateTrackingCLose = () => {
    setIsDeleteTracking(!isDeleteTracking);
    removeBodyCss();
    setFulfilmentIds("");
    dispatch(getTrackingNumber(""));
  };

  const handleFilePopup = () => {
    setIsOpeFiles(!isOpeFiles);
  };

  const handleDigitalFiles = (digital_product_id, product_id) => {
    const data = {
      product_id,
      digital_product_id,
      order_id: params?.orderNumber,
      customer_id: orderData?.order_detail?.customer_id,
    };
    setLoading(true);
    getDigitalFiles(data)
      .then(res => {
        setDigitalFiles(res.data);
        handleFilePopup();
      })
      .catch(err => {
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response.data.message)
            ? err?.response.data.message[0]
            : err?.response.data.message,
        });
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this message?"}
          onConfirm={() => handleDelete()}
          onCancel={() => {
            setDeleteMessageId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      {orderData?.order_status === "Hold" && <WarningOrder />}
      {orderData?.dispute_status || orderData?.order_dispute_status ? (
        <div className="rounded px-4 py-3 bg-danger text-danger text-white font-bold mb-4 text-15">
          Dispute Status:
          <span className="ms-1">
            {orderData?.dispute_status || orderData?.order_dispute_status}
          </span>
        </div>
      ) : null}
      <div className="order-detail-heading-section d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <TopHeading heading={`ORDER#:${params.orderNumber}`} />
          <div className="tracking-btn ms-2">Tracking Number</div>
          <div className="ali-express-btn ms-1">Ali Express Number</div>
        </div>
        <div className="d-flex ">
          <div>
            <ReactToPrint
              trigger={() => (
                <Button className="" color="primary">
                  Print Preview
                </Button>
              )}
              content={() => invoiceRef.current}
            />
          </div>
        </div>
      </div>
      <div className="order-detail-body-section">
        <Row>
          <Col xs="9">
            <Card>
              <CardBody>
                <div>
                  <ProductDetail
                    data={orderData?.orderLineItems}
                    handleUpdateTracking={handleUpdateTracking}
                    setApprovePayment={setApprovePayment}
                    setSellerId={setSellerId}
                    setFulfilmentIds={setFulfilmentIds}
                    handleDigitalFiles={handleDigitalFiles}
                  />
                </div>
              </CardBody>
            </Card>
            {orderData?.orderRefund && (orderData?.orderRefund).length > 0 ? (
              <Card>
                <CardBody>
                  {orderData?.orderRefund && (orderData?.orderRefund).length > 0
                    ? (orderData?.orderRefund).map((item, index) => {
                        let isPartial =
                          (item.is_partial_refund === 1 && "Partial Refund") ||
                          undefined;

                        return (
                          <div className="mt-4" key={index}>
                            <OrderDetailTable
                              columns={refundColumns}
                              refundReason={item?.note}
                              data={item?.line_items?.map((refund, index1) => {
                                return {
                                  ...refund,
                                  index: index1 + 1,
                                };
                              })}
                              heading={`Refund ${index + 1}`}
                              isPartial={isPartial}
                              status={item?.status}
                              refundTotal={item?.subtotal}
                            />
                          </div>
                        );
                      })
                    : null}
                </CardBody>
              </Card>
            ) : null}
            <Row>
              <Col xs="6">
                <OrderDetailsComponent
                  handleClick={handleEmail}
                  data={orderData?.order_detail}
                />
              </Col>
              <Col xs="6">
                {hasFinancialPermission && (
                  <PaymentDetailComponent data={orderData?.payment_detail} />
                )}
              </Col>
            </Row>
            {additionalMessages?.length > 0 && (
              <Card>
                <CardBody>
                  <OrderDetailTable
                    columns={messageColumns}
                    data={messagesData}
                    heading={"Notes"}
                    isNotesDelete={permissions?.includes("comment-delete")}
                  />
                  {messagePageCount > 1 && (
                    <PrimaryPagination
                      pageNo={messagePage}
                      setPageNo={setMessagePage}
                      pageCount={messagePageCount}
                    />
                  )}
                </CardBody>
              </Card>
            )}
            {ordersHistory?.length > 0 && (
              <>
                <Suspense fallback={<div></div>}>
                  <HistoryComponet
                    data={ordersHistory}
                    setPageNo={setOrderHistoryPage}
                    pageCount={orderHistoryPageCount}
                    pageNo={orderHistoryPage}
                  />
                </Suspense>
              </>
            )}
          </Col>
          <Col xs="3">
            <div className="action-section">
              {permissions?.includes("refund-order") &&
                permissions?.includes("financial-details") && (
                  <Button
                    size="lg"
                    block
                    color="primary"
                    className="mb-3"
                    onClick={handleRefundOrderPopup}
                  >
                    Refund Order
                  </Button>
                )}
              {permissions?.includes("tracking-number") &&
                orderData?.is_tracking !== 1 && (
                  <Button
                    size="lg"
                    block
                    color="primary"
                    className="mb-3"
                    onClick={handleAddTrackingPopup}
                  >
                    Add Tracking Number
                  </Button>
                )}

              {permissions?.includes("order-hold") && (
                <Button
                  size="lg"
                  block
                  color="primary"
                  className=""
                  onClick={handleHoldOrder}
                >
                  {orderData?.order_status !== "Hold"
                    ? "Hold Order"
                    : "Unhold Order"}
                </Button>
              )}
            </div>
            <div className="mt-4">
              <AddressComponent
                heading={"Shipping Address"}
                address={`${
                  orderData?.shipping_address?.address1
                    ? `${
                        orderData?.shipping_address?.address
                          ? `${orderData?.shipping_address?.address},`
                          : ""
                      }`
                    : `${
                        orderData?.shipping_address?.address
                          ? `${orderData?.shipping_address?.address}`
                          : ""
                      }`
                }  ${
                  orderData?.shipping_address?.address1
                    ? `${orderData?.shipping_address?.address1}`
                    : ""
                }`}
                city={`${
                  orderData?.shipping_address?.city
                    ? orderData?.shipping_address?.city
                    : ""
                }`}
                state={
                  orderData?.shipping_address?.province
                    ? orderData?.shipping_address?.province
                    : ""
                }
                zip={
                  orderData?.shipping_address?.zip
                    ? orderData?.shipping_address?.zip
                    : ""
                }
                country={
                  orderData?.shipping_address?.country
                    ? orderData?.shipping_address?.country
                    : ""
                }
                name={
                  orderData?.shipping_address?.name
                    ? orderData?.shipping_address?.name
                    : ""
                }
                handleClick={() => handleEditAddress("shipping")}
                phone={
                  orderData?.shipping_address?.phone
                    ? orderData?.shipping_address?.phone
                    : ""
                }
                isEditPermission={permissions?.includes("order-address-edit")}
              />
            </div>
            <div className="mt-4">
              <AddressComponent
                heading={"Billing Address"}
                address={`${
                  orderData?.billing_address?.address1
                    ? `${
                        orderData?.billing_address?.address
                          ? `${orderData?.billing_address?.address},`
                          : ""
                      }`
                    : `${
                        orderData?.billing_address?.address
                          ? `${orderData?.billing_address?.address}`
                          : ""
                      }`
                }  ${
                  orderData?.billing_address?.address1
                    ? `${orderData?.billing_address?.address1}`
                    : ""
                }`}
                city={`${
                  orderData?.billing_address?.city
                    ? orderData?.billing_address?.city
                    : ""
                }`}
                state={
                  orderData?.billing_address?.province
                    ? orderData?.billing_address?.province
                    : ""
                }
                zip={
                  orderData?.billing_address?.zip
                    ? orderData?.billing_address?.zip
                    : ""
                }
                country={
                  orderData?.billing_address?.country
                    ? orderData?.billing_address?.country
                    : ""
                }
                name={orderData?.billing_address?.name}
                handleClick={() => handleEditAddress("billing")}
                phone={orderData?.billing_address?.phone}
                isEditPermission={permissions?.includes("order-address-edit")}
              />
            </div>
            <div className="mt-4">
              <ClientDetailsComponent clientDetail={orderData?.client_detail} />
            </div>
            <Suspense fallback>
              <div className="mt-4">
                <AddMessageComponent
                  handleAddMessage={handleAddMessage}
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                />
              </div>
            </Suspense>
          </Col>
        </Row>
      </div>
      {isRefundOrder && (
        <PrimaryPopup
          title={"Order Refund"}
          isOpen={isRefundOrder}
          className={"primary-popup-container"}
          isScrollable={true}
          handleClick={handleRefundOrderPopup}
        >
          <RefundOrderPopup
            handleClick={handleRefundOrderPopup}
            orderNo={params?.orderNumber}
            userName={userInfo?.name}
            historyId={ordersHistory[0]?.id}
          />
        </PrimaryPopup>
      )}
      {isAddTracking && (
        <PrimaryPopup
          title={"Add Tracking Number"}
          isOpen={isAddTracking}
          className={"primary-popup-container"}
          isScrollable={true}
          handleClick={handleAddTrackingPopup}
        >
          <AddTrackingPopup
            handleCreateTracking={handleCreateTracking}
            id={params?.orderNumber}
            handleClick={handleAddTrackingPopup}
            loading={loading}
          />
        </PrimaryPopup>
      )}

      {isDeleteTracking && (
        <PrimaryPopup
          title={"Update Tracking Number"}
          isOpen={isDeleteTracking}
          className={"primary-popup-container"}
          isScrollable={true}
          handleClick={handleUpdateTrackingCLose}
        >
          <DeleteTrackingPopup
            handleClick={handleUpdateTrackingCLose}
            id={fulfillmentId}
            handleDeleteTracKing={handleDeleteTracKing}
            handleUpdateTrackingNumber={handleUpdateTrackingNumber}
            hasEditPermission={permissions?.includes("tracking-edit")}
            hasDeletePermission={permissions?.includes("tracking-delete")}
          />
        </PrimaryPopup>
      )}

      {isHoldOrder && (
        <HoldOrderPopup
          isOpen={isHoldOrder}
          heading={"Hold Order"}
          isScrollable={false}
          handleClick={handleHoldOrder}
          description={`Are you sure you want to make this order ${
            orderData?.order_status !== "Hold" ? "Hold" : "Unhold"
          }?`}
          handleYes={handleOrderHold}
          loading={loading}
        />
      )}
      {isApprovePayment && (
        <HoldOrderPopup
          loading={loading}
          isOpen={isApprovePayment}
          heading={"Approve Payment"}
          isScrollable={false}
          handleClick={() => {
            setApprovePayment(!isApprovePayment);
          }}
          description={"Are you sure you want to approve this payment?"}
          handleYes={approvePayment}
        />
      )}

      {isEditShippingAddress && (
        <PrimaryPopup
          title={"Update Shipping Address"}
          isOpen={isEditShippingAddress}
          className={"primary-popup-container"}
          isScrollable={true}
          handleClick={() => handleEditAddress("shipping")}
        >
          <EditAddressPopup
            type={"shipping"}
            fields={fields}
            setFields={setFields}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            id={params?.orderNumber}
            handleClick={() => handleEditAddress("shipping")}
            isProvinceText={isProvinceText}
            setIsProvinceText={setIsProvinceText}
            loading={loading}
          />
        </PrimaryPopup>
      )}

      {isEditBillingAddress && (
        <PrimaryPopup
          title={"Update Billing Address"}
          isOpen={isEditBillingAddress}
          className={"primary-popup-container"}
          isScrollable={true}
          handleClick={() => handleEditAddress("billing")}
        >
          <EditAddressPopup
            loading={loading}
            type={"billing"}
            fields={fields}
            setFields={setFields}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            id={params?.orderNumber}
            handleClick={() => handleEditAddress("billing")}
            isProvinceText={isProvinceText}
            setIsProvinceText={setIsProvinceText}
          />
        </PrimaryPopup>
      )}
      {isOpeFiles && (
        <DownloadDigalFile
          isOpeFiles={isOpeFiles}
          files={digitelsFiles}
          handleFilePopup={handleFilePopup}
        />
      )}
      {isEmailUpdate && (
        <EmailUpdatePopup
          loading={loading}
          email={email}
          setEmail={setEmail}
          handleEmailUpdate={handleEmailUpdate}
          handleEmail={handleEmail}
          isEmailUpdate={isEmailUpdate}
        />
      )}
      <div ref={invoiceRef} className="print-source  p-4">
        <InvoicePrint data={orderData} />
      </div>
    </div>
  );
};

export default OrderDetail;
