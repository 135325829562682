import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import { useNavigate, Link } from "react-router-dom";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import plusIcon from "../../../assets/images/plus-icon.svg";
import {
  getAllCategories,
  deleteCategory,
  downloadCategoriesCsv,
} from "services/ProductsServices";
import PlaceholderImage from "assets/images/placeholder.png";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import { useSelector } from "react-redux";
import DeleteModal from "components/Common/Toast/DeleteModal";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ExportButton from "components/Common/ExportButton";
import ResetButton from "components/Common/ResetButton";

function ProductCategories() {
  const [productCategories, setProductCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [searchVal, setSearch] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isHiddenCatShow, setHiddenCatShow] = useState("");
  const params = new URLSearchParams(window?.location?.search);
  const user = useSelector(state => state.auth?.user);
  const permissions = user?.permissions?.map(item => item.name);
  const paramsLength = Array.from(params.keys()).length;

  const history = useNavigate();

  useEffect(() => {
    setPageTitle("Product Categories - Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params?.get("page") || undefined;
    const search = params?.get("search") || undefined;
    const is_hidden = params?.get("hidden") || undefined;
    setSearch(search);
    setPageNo(page || 1);
    setHiddenCatShow(is_hidden);
    getCategories(page, search, is_hidden);
  }, [window?.location?.search]);

  const getCategories = (page, search, isHidden) => {
    setLoading(true);
    getAllCategories(page, search, isHidden)
      .then(res => {
        setPageCount(res.data.last_page);
        setProductCategories(res.data.data);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleFilters = () => {
    if (params?.get("page")) {
      params?.delete("page");
    }
    if (searchVal) {
      params?.set("search", searchVal);
    } else {
      params?.delete("search");
    }
    if (isHiddenCatShow) {
      params.set("hidden", 1);
    } else {
      params.delete("hidden");
    }
    history(`/products/categories?${params?.toString()}`);
  };
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleFilters();
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "name",
      text: "Category Name",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          {row?.image && (
            <img
              src={row?.image}
              alt="category image"
              className="me-2 rounded"
              height={40}
              width={40}
            />
          )}
          {!row.image && (
            <img
              src={PlaceholderImage}
              alt="category image"
              className="me-2 rounded"
              height={40}
              width={40}
            />
          )}
          <span>{row?.name}</span>
        </div>
      ),
    },
    {
      dataField: "slug",
      text: "Category Slug",

      formatter: (cell, row) => {
        let url = `https://www.inspireuplift.com/c/${cell}`;
        if (window.location.hostname.includes("itdeptiu")) {
          url = `https://staging.itdeptiu.com/c/${cell}`;
        }
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {cell}
          </a>
        );
      },
    },
    {
      dataField: "parent",
      text: "Parent Category",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">
          <Link
            to={`/products/categories/edit/${row?.id}`}
            className="action-btn edit-icon-bg me-1 cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={editIcon} className="" alt="edit icon" />
          </Link>
          {permissions?.includes("categories-delete") && (
            <div
              className="action-btn  delete-btn-bg me-1 cursor-pointer"
              onClick={() => {
                setShowDeleteModal(true);
                setSelectedId(row?.id);
              }}
            >
              <img src={deleteIcon} className="" alt="edit icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = productCategories?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDeleteCategory = () => {
    const filterArr = productCategories?.filter(item => item.id !== selectedId);
    setLoading(true);
    deleteCategory(selectedId)
      .then(res => {
        setSelectedId();
        setShowDeleteModal(false);
        if (filterArr?.length == 0 && pageNo > 1) {
          params.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          getCategories(pageNo, searchVal);
        }
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err, err?.response?.data?.message);
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {});
  };

  const downloadCSV = async () => {
    try {
      const response = await downloadCategoriesCsv(searchVal);
      return response;
    } catch (err) {
      console.log(err);
      throw err;
}
  };

  return (
    <div className="page-content category-page-container">
      {loading && <ProgressPopup label={"Loading..."} />}
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this category?"}
          onCancel={() => {
            setShowDeleteModal(false);
            setSelectedId("");
          }}
          onConfirm={handleDeleteCategory}
        />
      )}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Product Categories"} />
        <Button
          color="primary"
          className=""
          onClick={() => history("/products/categories/create")}
        >
          <img src={plusIcon} className="mdi mdi-plus-circle-outline me-1" />
          Create New Category
        </Button>
      </div>
      <Card>
        <CardBody>
          <div className="me-2 mb-3 d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <PrimarySearch
                handleKeyPress={handleKeyPress}
                value={searchVal || ""}
                placeholder={"Search by category name, slug "}
                handleChange={handleSearch}
              />
              <select
                className="primary-select-option ms-1"
                name="hidden"
                value={isHiddenCatShow || ""}
                onChange={e => {
                  setHiddenCatShow(e.target.value);
                }}
                onKeyPress={handleKeyPress}
              >
                <option value={""}>Status</option>
                <option value={1}>Hidden</option>
              </select>
              <Button
                color="primary"
                className={`btn-rounded  ms-1`}
                onClick={handleFilters}
                disabled={!searchVal && !isHiddenCatShow}
              >
                Apply Filter
              </Button>
              {paramsLength > 0 && <ResetButton />}
            </div>
            <ExportButton
              fetchData={downloadCSV}
              isLoading={loading}
              setIsLoading={setLoading}
            />
          </div>
          <div className="table-responsive">
            <PrimaryTable
              keyField="id"
              data={data}
              columns={columns}
              bordered={false}
            />
            {productCategories?.length > 0 && pageCount > 1 && (
              <PrimaryPagination
                pageNo={pageNo}
                pageCount={pageCount}
                setPageNo={setPageNo}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default ProductCategories;
