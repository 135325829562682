import React, { useState, useEffect } from "react";
import ImagePreviewPopup from "components/Common/Popups/ImagePreviewPopup";

const MessageSection = ({
  data,
  description,
  handleShowGallery
}) => {
  const [isPreview, setIsPreview] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(()=>{

    document.querySelectorAll(".description-image").forEach((img,index)=>{
      img.addEventListener("click",()=>handleShowGallery(index));
    })

  },[description])

  const handlePreview = imgUrl => {
    setImageUrl(imgUrl);
    setIsPreview(true);
  };

  const handlePreviewClose = () => {
    setImageUrl("");
    setIsPreview(!isPreview);
  };

  const isValidURL = value => {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(value);
  };

  const getUrlOrText = (value) => {
	const parts = value?.split(' ');
	let result = '';
  
	parts?.forEach((part, index) => {
	  if (isValidURL(part)) {
		result += `<a href="${part}" target="_blank" rel="noopener noreferrer">${part}</a> `;
	  } else {
		result += `${part} `;
	  }
	});
  
	return <div dangerouslySetInnerHTML={{ __html: result }} className="img-ref" />;
  };
  

  if (!data) {
    return null; // Return null when data is not available yet
  }

  return (
    <div className="py-2 border-bottom">
      <div className="d-flex align-items-start">
        {data.assigned_by ? (
          <div className="employee-name-avatar text-uppercase">
            {data.assignBy_media ? (
              <img
                src={data.assignBy_media}
                className="employee-name-avatar"
                alt="image"
              />
            ) : (
              data.assigned_by[0]
            )}
          </div>
        ) : null}
        {data.admin ? (
          <div className="employee-name-avatar text-uppercase">
            {data.admin_media ? (
              <img
                src={data.admin_media}
                className="employee-name-avatar"
                alt="image"
              />
            ) : data.admin?.length > 0 ? (
              data.admin[0]
            ) : null}
          </div>
        ) : null}

        <div className="ms-3" style={{ width: "90%" }}>
          <div className="d-flex align-items-center">
            {data.admin ? (
              <span className="text-13 font-semi-bold">{data.admin}</span>
            ) : null}
            {data.assigned_by ? (
              <span className="text-13 font-semi-bold">
                {data.assigned_by}
              </span>
            ) : null}

            {data.assigned_on ? (
              <span className="text-13 ps-2">{data.assigned_on}</span>
            ) : null}
            {data.created ? (
              <span className="text-13 ps-2">{data.created}</span>
            ) : null}
          </div>
          <div className="font-bold text-13">{data.title}</div>
          {description ? 	 <div dangerouslySetInnerHTML={{ __html: description }} className="" /> : null}
          <p className="mt-1">{getUrlOrText(data?.comment)}</p>
          {data.media_url ? (
            <img
              src={data.media_url}
              onClick={() => handlePreview(data.media_url)}
              alt="task image"
              height={150}
              className="me-2"
              width={200}
              style={{ objectFit: "cover" }}
            />
          ) : null}
        </div>
        {isPreview && (
          <ImagePreviewPopup
            handleClick={handlePreviewClose}
            isOpen={isPreview}
            url={imageUrl}
          />
        )}
      </div>
    </div>
  );
};

export default MessageSection;
