import React, { useState, useEffect } from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";
import {
  deleteProductsByProductIds,
  deleteProductsBySellerId,
  getProductsBySku,
  updateProductCategory,
} from "services/ProductsServices";
import ProgressPopup from "./ProgressPopup";
import PrimaryToast from "../Toast/ToastMessage";
import { getCategories } from "services/ProductsServices";
import Select from "react-select";

function ProductActionPopup({
  isOpen,
  handleClick,
  inputValue,
  setInputValue,
  setProductDataBySku,
  productDataBySku,
  popupType,
  selectedId
}) {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (popupType == "edit") {
      getCategories()
        .then(res => {
          const categoriesList = [
            ...res.data.map(item => {
              return {
                label: item.name,
                value: item.id,
              };
            }),
          ];
          setCategories(categoriesList);
        })
        .catch(err => console.log(err))
        .finally(res => setLoading(false));
    }
  }, [popupType]);

  const handleChange = e => {
    const regex = /^[0-9]+$/;
    const value = e.target.value;
    if (popupType === "seller id" && !regex.test(value) && value !== "") {
      return;
    }
    setInputValue(value);
  };

  const handleAction = () => {
    setLoading(true);
    if (popupType == "sku") {
      getProductsBySku(inputValue)
        .then(res => {
          console.log(res);
          setProductDataBySku(res.data);
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    } else if (popupType == "seller id") {
      deleteProductsBySellerId(inputValue)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Products Delete Successfully.",
          });
          handleClick();
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    } else if (popupType === "product id") {
      const payload = inputValue?.split(",")?.map(id => Number(id));
      deleteProductsByProductIds(payload)
        .then(res => {
          PrimaryToast({
            type: "success",
            message: "Products Delete Successfully.",
          });
          handleClick();
        })
        .catch(err => {
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
        .finally(res => setLoading(false));
    }
    else if (popupType === "edit"){
        updateProductCategory(selectedId, inputValue)
            .then((res) => {
                PrimaryToast({
                    type: "success",
                    message: "Products Update Successfully.",
                  });
                  handleClick();
            })
            .catch(err => {
                console.log(err);
                PrimaryToast({
                  type: "error",
                  message: err?.response?.data?.message,
                });
            })
            .finally(res => setLoading(false));

    }
  };
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClick}
      title={`${
        popupType == "sku"
          ? "Search by SKU"
          : popupType == "seller id"
          ? "Delete Products By Seller ID"
          : popupType == "product id"
          ? "Delete Products by ID"
          : popupType == "edit"
          ? "Assign Category"
          : ""
      }`}
      isScrollable={false}
      className={`d-flex justify-content-center align-items-center h-100 w-100 ${
        popupType.includes("id")
          ? "delete-product-by-sellerId-popup"
          : popupType == "sku"
          ? "product-sku-popup"
          : ""
      }`}
    >
      {loading && <ProgressPopup label={"Loading..."} />}
      <div>
        <div className="mb-3">
          <label htmlFor="inputValue">
            {popupType == "seller id"
              ? "Seller Id"
              : popupType == "product id"
              ? "Product IDs"
              : popupType == "edit"
              ? "Select Category"
              : "SKU"}
          </label>
          {popupType == "edit" ? (
            <Select
              isMulti={false}
              options={categories}
              onChange={item => {
                setInputValue(item?.value);
              }}
              //   value={subCat}
              name={"assign_category"}
              className="react-select-container"
              placeholder={"Please select category"}
            />
          ) : (
            <input
              type="text"
              className={`form-control ${
                popupType.includes("id") ? "w-100" : "w-50"
              }`}
              id="inputValue"
              placeholder={`${
                popupType == "seller id"
                  ? "Enter Seller ID"
                  : popupType == "product id"
                  ? "Enter Product ids separated by comma(,)"
                  : "Enter Product SKU"
              }`}
              value={inputValue || ""}
              name="product_sku"
              onChange={handleChange}
            />
          )}
        </div>
        <div className="d-flex  justify-content-end">
          <Button
            disabled={inputValue?.length == 0 || !inputValue}
            color="primary"
            onClick={handleAction}
          >
            Submit
          </Button>
        </div>
        {popupType === "sku" && (
          <div
            className="overflow-y-auto table-responsive mt-3"
            style={{ maxHeight: "600px" }}
          >
            {productDataBySku}
          </div>
        )}
      </div>
    </PrimaryPopup>
  );
}

export default ProductActionPopup;
