import { getApi } from "./BaseService";
import moment from "moment";


export const getDashBoardData = (start,end) => {
    return getApi({
        url:`admin/dashboard`,
        data:{
            is_admin:true,
            startDate:moment(start).format("MM/DD/YYYY") || undefined,
            endDate:moment(end).format("MM/DD/YYYY") || undefined,
        },

    }).then(res => (res.data))
}