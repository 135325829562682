import moment from "moment";
import 'moment-timezone';

export const getCurrentTimeInEST = () => {
    const inputDate = new Date();
    const inputTimeZone = "Asia/Karachi";

    // Parse the input date with the given format and timezone
    const parsedDate = moment.tz(
      inputDate,
      "YYYY-MM-DD hh:mm:ss a",
      inputTimeZone
    );

    // Convert it to Eastern Standard Time (EST)
    const estTimeZone = "America/New_York"; // Eastern Standard Time
    const estDateTime = parsedDate
      .clone()
      .tz(estTimeZone)
      .format("YYYY-MM-DD hh:mm:ss A");
    return estDateTime;  
}