import TopHeading from "components/Common/TopHeading";
import React, { useState, useEffect } from "react";
import { CardBody, Card, Button } from "reactstrap";
import PrimaryInput from "components/Common/PrimaryInput";
import CalendarInput from "components/Common/CalenderInput";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SwitchButton from "components/Common/SwitchButton";
import SecondaryInput from "components/Common/SecondaryInput";
import { createCampaign } from "services/CampaignsServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import { useSelector } from "react-redux";

const CreateCampaigns = () => {
  const [showRegistrationCalender, setShowRegistrationCalender] =
    useState(false);
  const [showStartDateCalender, setShowStartDateCalender] = useState(false);
  const [showEndDateCalendar, setShowEndDateCalender] = useState(false);
  const [registrationCloseIN, setRegistrationCloseIN] = useState("");
  const [cName, setCName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isCampaignInvite, setIsCampaignInvite] = useState(false);
  const [storeIds, setStoreIds] = useState("");
  const [minConversion, setMinConversion] = useState("");
  const [maxProducts, setMaxProducts] = useState("");
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [slug, setSLug] = useState("");
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);

  useEffect(() => {
    setPageTitle("Create Campaign - Inspire Uplift");
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    let storeIdsArr = storeIds?.split(",")?.map(id => Number(id));
    let payload = {
      name: cName || undefined,
      start: moment(startDate).format("YYYY-MM-DD") || undefined,
      end: moment(endDate).format("YYYY-MM-DD") || undefined,
      register_end:
        moment(registrationCloseIN).format("YYYY-MM-DD") || undefined,
      status: status ? 1 : 0,
      min_conversion: minConversion || undefined,
      max_products: maxProducts || undefined,
      invited_sellers: isCampaignInvite ? storeIdsArr : null,
      slug,
    };
    setLoading(true);
    createCampaign(payload)
      .then(res => {
        navigate("/marketing/campaigns");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <TopHeading heading={"Create Campaign"} />
          <div className="d-flex">
            <Button
              className="me-2"
              disabled={loading}
              onClick={() => navigate("/marketing/campaigns")}
            >
              Cancel
            </Button>
            {permissions?.includes("campaign-create") ? (
              <Button color="primary" type="submit" disabled={loading}>
                Create
              </Button>
            ) : null}
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <h2 className="h5">Campaign Information</h2>
            <div className="mb-3">
              <PrimaryInput
                label={"Name"}
                isRequired
                name="title"
                placeholder={"Enter Campaign Name"}
                value={cName || ""}
                handleChange={e => setCName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Campaign Slug"}
                isRequired
                name="slug"
                placeholder={"Enter Campaign slug"}
                value={slug || ""}
                handleChange={e => setSLug(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <CalendarInput
                label="Registration Close In"
                isRequired={true}
                handleShowCalender={() =>
                  setShowRegistrationCalender(!showRegistrationCalender)
                }
                showCalender={showRegistrationCalender}
                handleChange={newDate => {
                  setRegistrationCloseIN(newDate)
                  }}
                minDate={moment().toDate()}
                maxDate={maxDate}
                date={
                  registrationCloseIN
                    ? new Date(registrationCloseIN)
                    : new Date()
                }
                value={
                  registrationCloseIN &&
                  moment(registrationCloseIN).format("MM-DD-YYYY")
                }
                styling={"registration-closein"}
                placeholder={"Select Registration Close In"}
              />
            </div>
            <div className="mb-3 d-flex">
              <div className="w-50">
                <CalendarInput
                  label="Start Date"
                  isRequired={true}
                  handleShowCalender={() =>
                    setShowStartDateCalender(!showStartDateCalender)
                  }
                  showCalender={showStartDateCalender}
                  handleChange={newDate => {
                    setStartDate(newDate);
                    setShowStartDateCalender(false);
                  }}
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  date={startDate ? new Date(startDate) : new Date()}
                  value={startDate && moment(startDate).format("MM-DD-YYYY")}
                  placeholder={"Select Start Date"}
                />
              </div>
              <div className="ms-4 w-50">
                <CalendarInput
                  label="End Date"
                  isRequired={true}
                  handleShowCalender={() =>
                    setShowEndDateCalender(!showEndDateCalendar)
                  }
                  showCalender={showEndDateCalendar}
                  handleChange={newDate => {
                    setEndDate(newDate)
                    setShowEndDateCalender(false);
                  }}
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  date={endDate ? new Date(endDate) : new Date()}
                  value={endDate && moment(endDate).format("MM-DD-YYYY")}
                  placeholder={"Select End Date"}
                />
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="w-50">
                <label className="form-label">
                  Minimum Discount <span className="primary-text">*</span>
                </label>
                <SecondaryInput
                  name="min_conversion"
                  margin={"mb-0"}
                  placeholder={"Enter Min Discount"}
                  text={"%"}
                  handleChange={e => setMinConversion(e.target.value)}
                />
              </div>
              <div className="w-50 ms-4">
                <PrimaryInput
                  label={"Seller Max Product"}
                  isRequired
                  name="max_products"
                  placeholder={"Enter Maximum Products"}
                  value={maxProducts || ""}
                  handleChange={e => setMaxProducts(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmail4">
                Status<span className="primary-text">*</span>
              </label>
              <SwitchButton
                checked={status}
                label={status ? "Active" : "Inactive"}
                name="status"
                value={status}
                handleChange={e => {
                  console.log(e.target.checked);
                  setStatus(e.target.checked);
                }}
              />
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value={isCampaignInvite}
                id="isCampaignInvite"
                name="isCampaignInvite"
                checked={isCampaignInvite}
                onChange={e => {
                  console.log(e.target.checked);
                  setIsCampaignInvite(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="isCampaignInvite">
                The campaign is invite only
              </label>
            </div>
            {isCampaignInvite ? (
              <div className="d-flex align-items-end w-100">
                <div className="me-3 w-100">
                  <PrimaryInput
                    name="store_ids"
                    value={storeIds || ""}
                    label={"Seller Store Ids"}
                    isRequired
                    type={"text"}
                    placeholder="Enter Seller Store Ids*"
                    handleChange={e => {
                      setStoreIds(e.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </CardBody>
        </Card>
      </form>
    </div>
  );
};

export default CreateCampaigns;
