import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getEmailsList = (page, search, status) => {
    return getApi({
        url:`admin/dmca-emails`,
        data:{
            is_admin:true,
            page,
            search:search || undefined,
            status: status || undefined,
        }
    }).then(res => res.data);
};

export const getEmailDetail = (id) => {
    return getApi({
        url:`admin/dmca-emails-detail`,
        data:{
            is_admin:true,
            id
        }
    }).then(res => res.data);
}

export const emailAction = (data) => {
    return axiosInstance({
        method:"POST",
        url:`${siteApiUrl}admin/dmca-emails-action`,
        withCredentials:true,
        data:{
            ...data,
            is_admin:true
        },
        headers:getHttpHeaders()
    })
};

export const dmcaDelete = (id) => {
    return axiosInstance({
        method:"POST",
        url:`${siteApiUrl}admin/dmca-emails-delete`,
        withCredentials:true,
        data:{
            id,
            is_admin:true
        },
        headers:getHttpHeaders()
    })
}