import React from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import { useState, useEffect } from "react";
import {
  getAdminAndSellerLoginLogs,
  getLogsList,
  payoutMethodLogs,
} from "services/SellerStoreServices";
import { useParams } from "react-router-dom";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { setPageTitle } from "utils/pageTitle";

function SellerLogs() {
  const [logsData, setLogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userPageNo, setUserPageNo] = useState(1);
  const [userPageCount, setPageCount] = useState();
  const [payoutLogsData, setPayoutLogsData] = useState([]);
  const [payoutLogsPage, setPayoutLogsPage] = useState(1);
  const [payoutLogsCount, setPayoutLogsCount] = useState();
  const [loginHistoryData, setLoginHistoryData] = useState([]);
  const [loginHistoryPage, setLoginHistoryPage] = useState(1);
  const [loginHistoryPageCount, setLoginHistoryPageCount] = useState();
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setPageTitle("Seller Logs - Inspire Uplift");
  }, []);

  useEffect(() => {
    const uPageNo = urlParams.get("u-page-no") || 1;
    setUserPageNo(uPageNo);
    setLoading(true);
    getLogsList(id, uPageNo)
      .then(res => {
        setLogsData(res.data.data);
        setPageCount(res.data.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [urlParams?.get("u-page-no")]);

  useEffect(() => {
    const pPageNo = urlParams.get("p-page-no") || 1;
    setPayoutLogsPage(pPageNo);
    setLoading(true);
    payoutMethodLogs(id, pPageNo)
      .then(res => {
        setPayoutLogsData(res.data.data);
        setPayoutLogsCount(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, [urlParams?.get("p-page-no")]);

  useEffect(() => {
    const pageNo = urlParams.get("l-page-no") || 1;
    setLoginHistoryPage(pageNo);
    fetchLoginHistoryData(pageNo);
  }, [urlParams?.get("l-page-no")]);

  const fetchLoginHistoryData = async pageNo => {
    try {
      const response = await getAdminAndSellerLoginLogs(id, pageNo);
      setLoginHistoryData(response.data.data);
      setLoginHistoryPageCount(response.data.last_page);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return <div>{(userPageNo - 1) * 10 + row?.index}</div>;
      },
      style: {
        verticalAlign: "top",
      },
    },
    {
      dataField: "type",
      text: "Type",
      style: {
        verticalAlign: "top",
      },
    },
    {
      dataField: "logs",
      text: "Logs",
      formatter: (cellContent, row) => {
        return (
          <div>
            {Object.entries(row.data).length > 0
              ? Object.entries(row.data).map(([key, value], index) => (
                  <div className="d-flex align-items-center mb-2" key={index}>
                    <div className="font-semi-bold me-2 text-capitalize">
                      {key.replaceAll("_", " ")}:
                    </div>
                    <div>{value}</div>
                  </div>
                ))
              : null}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      style: {
        verticalAlign: "top",
      },
    },
  ];

  const data = logsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const paymentCol = [
    {
      dataField: "created_at",
      text: "Date",
    },
    {
      dataField: "admin",
      text: "Action By",
    },
    {
      dataField: "old_data",
      text: "Old Data",
      formatter: (cell, row) => {
        return <div>{row?.old_data && JSON.stringify(row?.old_data)}</div>;
      },
      style: {
        maxWidth: "200px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
      },
    },
    {
      dataField: "new_data",
      text: "New Data",
      style: {
        maxWidth: "200px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
      },
      formatter: (cell, row) => {
        return <div>{row?.new_data && JSON.stringify(row?.new_data)}</div>;
      },
    },
  ];

  const loginLogsCol = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return <div>{(loginHistoryPage - 1) * 10 + row?.index}</div>;
      },
      style: {
        verticalAlign: "top",
      },
    },
    {
      dataField: "logs",
      text: "Logs",
      formatter: (cellContent, row) => {
        // console.log(row,"check this row");
        return (
          <div>
            {Object.entries(row.data).length > 0
              ? Object.entries(row.data).map(([keys, values], index) => (
                  <div key={index} className="d-flex align-items-start mb-2">
                    <div className="font-semi-bold me-2 text-capitalize" style={{minWidth:"140px"}}>
                      {keys.replaceAll("_", " ")}:
                    </div>
                    <div style={{ maxWidth: "500px", whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(values)}
                    </div>
                  </div>
                ))
              : null}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      style: {
        verticalAlign: "top",
      },
    },
  ];

  const paymentData = payoutLogsData?.map(item => {
    return {
      ...item,
    };
  });

  const loginLogsData = loginHistoryData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"loading..."} />}
      <div className="d-flex justify-content-between mb-3">
        <TopHeading heading={"Logs"} />
      </div>
      <Card>
        <CardBody>
          <h5 className="mt-3">User Logs</h5>
          <div className="table-responsive mt-3">
            <PrimaryTable
              keyField={"id"}
              responsive
              bordered={false}
              striped={false}
              columns={columns}
              data={data}
            />
            {logsData && (
              <PrimaryPagination
                pageCount={userPageCount}
                pageNo={userPageNo}
                setPageNo={setUserPageNo}
                paramName="u-page-no"
              />
            )}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h5 className="mt-3">Payout Method Changes</h5>
          <div className="mt-3 table-responsive">
            <PrimaryTable
              keyField={"id"}
              responsive
              bordered={false}
              striped={false}
              columns={paymentCol}
              data={paymentData}
            />
          </div>
          {paymentData?.length > 0 && (
            <PrimaryPagination
              pageCount={payoutLogsCount}
              pageNo={payoutLogsPage}
              setPageNo={setPayoutLogsPage}
               paramName="p-page-no"
            />
          )}
        </CardBody>
      </Card>
      {loginHistoryData?.length > 0 && (
        <Card>
          <CardBody>
            <h5 className="mt-3">Seller/Admin Login History</h5>
            <div className="mt-3 table-responsive">
              <PrimaryTable
                keyField={"id"}
                responsive
                bordered={false}
                striped={false}
                columns={loginLogsCol}
                data={loginLogsData}
              />
            </div>
            {loginHistoryData?.length > 0 && (
              <PrimaryPagination
                pageCount={loginHistoryPageCount}
                pageNo={loginHistoryPage}
                setPageNo={setLoginHistoryPage}
                paramName="l-page-no"
              />
            )}
          </CardBody>
        </Card>
      )}
    </div>
  );
}

export default SellerLogs;
