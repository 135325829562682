import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import PlusIcon from "../../../assets/images/plus-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import PrimarySearch from "components/Common/PrimarySearch";
import {
  getAllCommandSchedules,
  deleteScheduleCommand,
} from "services/ScheduleCommandsService";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function ScheduleCommands() {
  const [scheduleData, setScheduleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchVal, setSearchVal] = useState(null);
  const params = new URLSearchParams(window?.location?.search);
  const history = useNavigate();

  useEffect(() => {
    setPageTitle("Schedule Commands - Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params?.get("page") || undefined;
    const search = params?.get("search") || undefined;
    setPageNo(page || 1);
    setSearchVal(search);
    setLoading(true);
    getScheduleCommandsList(page, search);
  }, [window?.location?.search]);

  const getScheduleCommandsList = (page, search) => {
    getAllCommandSchedules(page, search)
      .then(res => {
        setPageCount(res.data.last_page);
        setScheduleData(res.data.data);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return (
          <div className="font-semi-bold">{(pageNo - 1) * 10 + row?.index}</div>
        );
      },
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "command",
      text: "Command",
    },
    {
      dataField: "status",
      text: "Process Status",
      formatter: (cell, row) => {
        return (
          <div
            className={`status w-max-content 
			${row?.status == "Failed" && "danger-status"}
			${
              row.status === "Processed" && "success-status"
            } ${row.status === "Processing" && "warning-status"}`}
          >
            {row.status}
          </div>
        );
      },
    },
    {
      dataField: "last_processed",
      text: "Last Process",
      formatter: (cell, row) => row.status && <div>{row?.last_processed}</div>,
    },
    {
      dataField: "is_active",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`${
            row.is_active === 1 ? "success-status" : "danger-status"
          } status w-max-content`}
        >
          {row.is_active === 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <div
            className="action-btn edit-icon-bg me-1"
            onClick={() => history(`/others/schedule-command/edit/${row.id}`)}
          >
            <img src={editIcon} className="" alt="edit icon" />
          </div>
          <div
            className="action-btn delete-btn-bg me-1 cursor-pointer"
            onClick={() => {
              setSelectedId(row.id);
              setShowDeleteModal(true);
            }}
          >
            <img src={deleteIcon} className="" alt="delete icon" />
          </div>
        </div>
      ),
    },
  ];

  const handleSearch = e => {
    const value = e.target.value;
    setSearchVal(value);
  };

  const data = scheduleData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleDelete = () => {
    const filterData = scheduleData?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteScheduleCommand(selectedId)
      .then(res => {
        if (pageNo > 1 && filterData.length === 0) {
          params.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          getScheduleCommandsList(pageNo, searchVal);
        }
        setSelectedId();
        setShowDeleteModal(false);
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        setLoading(false)
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
     
  };

  const handleKeyPress = e => {
	if (e.key === "Enter") {
		if (params?.get("page")) {
		  params?.delete("page");
		}
		if (searchVal) {
		  params?.set("search", searchVal);
		} else {
		  params?.delete("search");
		}
		history(`/others/schedule-command?${params?.toString()}`);
	}
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this command?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Schedule Commands"} />
        <Button
          className="d-flex align-items-center"
          color="primary"
          onClick={() => history("/others/schedule-command/create")}
        >
          <img src={PlusIcon} alt="plus icon" className="pe-2" />
          Create New Command
        </Button>
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            placeholder={"Search by command name, command"}
            value={searchVal}
            handleChange={handleSearch}
            handleKeyPress={handleKeyPress}
          />
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="id"
            />
          </div>
          {scheduleData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageNo={pageNo}
              pageCount={pageCount}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ScheduleCommands;
