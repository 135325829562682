import React, { useState, useRef, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";
import { siteApiUrl } from "services/BaseService";
import PrimaryToast from "../Toast/ToastMessage";

export default function RichTextEditor({
  value,
  maxChar,
  disablePlugins,
  buttons,
  handleChange,
  name,
  uploader
}) {
  const editor = useRef(null);
  const [description, setDescription] = useState(value);
  useEffect(() => {
    if (!description) {
      setDescription(value);
    }
  }, [value]);

  const config = useMemo(
    () => ({
      toolbarButtonSize: "large",
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
    //   enter: "BR",
      defaultActionOnPaste: "insert_clear_html",
      readonly: false,
      placeholder: "Add Product Description",
      height: 400,
      limitChars: maxChar || 3000,
      language: "en",
      showWordsCounter: false,
      showXPathInStatusbar: false,
      hidePoweredByJodit: true,
      controls: {
        ul: {
          list: undefined,
        },
        ol: {
          list: undefined,
        },
      },
      cleanHTML: {
        denyTags: "script",
      },
      disablePlugins: disablePlugins,
      buttons: buttons,
      uploader: uploader ? {
        insertImageAsBase64URI: false,
        imagesExtensions: ["jpg", "png", "jpeg"],
        withCredentials: true,
        method: "POST",
        url: `${siteApiUrl}admin/tasks/upload-image`,
        headers: {
          Accept: "application/json",
        },
        prepareData: function (formData) {
          console.log(formData, "check formData");
          formData.append("galleryImage", formData.get("files[0]"));
          formData.append("is_admin", true);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msg;
        },
        process: function (resp) {

          if (!resp.status && resp?.message) {
            console.log(resp.message);
            PrimaryToast({
                type:'error',
                message: resp.message,
            })
          } else {
            return {
              file: resp.media?.url,
            };
          }
        },
        defaultHandlerSuccess: function (data, resp) {
          if (data?.file) {
            this.s.insertImage(data?.file);
          } else {
            return null;
          }
        },
      } : {},
    }),
    []
  );

  return (
    <JoditEditor
      ref={editor}
      value={description || ""}
      config={config}
      tabIndex={1}
      onChange={newContent => {
        handleChange({
          name: name,
          value: newContent,
        });
      }}
    />
  );
}
