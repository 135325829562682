import React, {useState, useEffect} from 'react';
import TopHeading from 'components/Common/TopHeading';
import DateFilter from 'components/Common/DateFilter';
import PrimaryCard from 'components/Common/PrimaryCard';
import { Row, Col } from 'reactstrap';
import WebIcon from "../../assets/images/web-icon.svg";
import IosIcon from "../../assets/images/ios-icon.svg";
import AndriodIcon from "../../assets/images/andriod-icon.svg";
import CartIcon from "../../assets/images/total-cart.svg";
import { getSalesByChannel } from 'services/ReportsServices';
import PrograssPopup from 'components/Common/Popups/ProgressPopup';
import { setPageTitle } from 'utils/pageTitle';

 

function SalesByChannel() {
	const [channelData, setChannelData] = useState();
	const [loading, setLoading] = useState(false);
	const [dates, setDate] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection',
	   });

	useEffect(()=>{
		setPageTitle("Sales By Channel - Inspire Uplift")
	},[]);

	useEffect(()=>{
		setLoading(true);
		getSalesByChannel(dates?.startDate,dates?.endDate)
			.then(res => {
				setChannelData(res?.data)
			})
			.catch(err => {
				console.log(err)
			})
			.finally(res => {
				setLoading(false);

			})

	},[dates?.startDate,dates?.endDate]);

  return (
    <div className='page-content reports-page-container'>
		{
			loading && <PrograssPopup label={"Loading..."} />
		}
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <TopHeading heading={"Sales By Channel"} />
        <DateFilter setDate={setDate} dates={dates} />
      </div>
      <Row>
		<Col>
			<PrimaryCard icon={WebIcon} title="Web" count={channelData?.web} />
		</Col>
		<Col>
			<PrimaryCard icon={IosIcon} title="IOS" count={channelData?.ios} />
		</Col>
		<Col>
			<PrimaryCard icon={AndriodIcon} title="Android" count={channelData?.android > 0 ? channelData?.android  : "0" } />
		</Col>
		<Col>
			<PrimaryCard icon={CartIcon} title="Total" count={channelData?.total} />
		</Col>
      </Row>
    </div>
  )
}

export default SalesByChannel