import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import tickIcon from "assets/images/tick-icon.svg";
import cancleIcon from "assets/images/cancle-icon.svg";
import rectangeArrow from "assets/images/rectangle-arrow.svg";
import { useNavigate } from "react-router-dom";
import {
  orderPaymentApproveList,
  orderPaymentReject,
  orderPaymentApprove,
} from "services/OrderPaymentApproveService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import moment from "moment";
import DateRangePicker from "components/Common/DateRangePicker";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import { openLinkInNewTab } from "utils/linkHandling";
import useToggle from "hooks/useToggle";
import ResetButton from "components/Common/ResetButton";

function OrderPaymentStatus() {
  const [getOrderPaymentApproveList, setOrderPaymentApproveList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [status, setStatus] = useState("");
  const params = new URLSearchParams(window.location.search);
  const paramsLength = Array.from(params.keys()).length;

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [searchVal, setSearch] = useState(null);
  const user = useSelector(state => state.auth.user);
  const hasPaymentApprovePer = user?.permissions?.some((p) => p.name === "payment-approved");
  const hasPaymentRejectPer = user?.permissions?.some((p) => p.name === "payment-rejected");
  const history = useNavigate();

  useEffect(()=>{
		setPageTitle("Seller Payment Approval - Inspire Uplift")
	},[]);


  useEffect(() => {
    const statusParam = params?.get("status");
    const startDate = params?.get("start") ;
    const endDate = params?.get("end");
    const search = params?.get("str") || null;
    const page = params?.get("page") || undefined;
    if (search) {
      setSearch(search);
    }
    let statusField;
    if (statusParam) {
      if (statusParam === "Approved") {
        statusField = 1;
      } else if (statusParam === "Rejected") {
        statusField = 2;
      } else if (statusParam === "Pending") {
        statusField = "0";
      }
    };

	setDates([
        {
          startDate: moment(startDate || new Date(), "DD-MM-YYYY").toDate(),
          endDate: moment(endDate || new Date(), "DD-MM-YYYY").toDate(),
          key: "selection",
        },
    ]);

    let applyFilter = {
      status: statusField || undefined,
      start_date: startDate || undefined,
      end_date: endDate || undefined,
    };
    setPageNo(page || 1);
    setSearch(search);
    setStatus(statusField || null);
	setLoading(true);
    getOrderPayments(page, search, applyFilter);
  }, [window?.location?.search]);

  const getOrderPayments = (page, search, status) => {
    orderPaymentApproveList(page, search, status)
      .then(res => {
        const newData = res?.data?.order_payments?.data.map(item => {
          return {
            id: item.id,
            order_id: item.order_id,
            status: item?.status,
            created_at: item?.created_at,
            seller_store: item?.seller_order?.seller_store,
            admin: item?.admin,
          };
        });
        setOrderPaymentApproveList(newData);
        setPageCount(res?.data?.order_payments?.last_page);
		setLoading(false)
      })
      .catch(err => {
		console.log(err);
		setLoading(false)
	})
  };

  const columns = [
    {
      dataField: "order_id",
      text: "Order ID",
      formatter: (cell, row) => <div className="font-semi-bold">{row.order_id}</div>,
    },
    {
      dataField: "seller_store",
      text: "Store Name",
      formatter: (cell, row) => <div>{row.seller_store}</div>,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        switch (row.status) {
          case 1:
            status = "Approved";
            break;
          case 2:
            status = "Rejected";
            break;
          default:
            status = "Pending";
        }
        return (
          <div
            className={`status ${
              status === "Pending"
                ? "warning-status"
                : status === "Approved"
                ? "success-status"
                : status === "Rejected"
                ? "danger-status"
                : ""
            }  w-max-content`}
          >
            {status}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell, row) => <div>{row.created_at}</div>,
    },
    {
      dataField: "admin",
      text: "Action By",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className={`d-flex align-items-center ${loading && "pe-none opacity-25"}`}>
            <Button
              color="primary"
              className="d-flex align-items-center me-1"
              onClick={() =>
                openLinkInNewTab(`/orders/details/${row?.order_id}`, "_blank")
              }
            >
              <img src={rectangeArrow} />
              <div className="ms-2">Order Detail</div>
            </Button>
            {hasPaymentRejectPer && (
              <div
                className={`action-btn delete-btn-bg cursor-pointer me-1 ${
                  row.status !== 0 && "d-none"
                }`}
                onClick={() => {
                  setSelectedId(row.id);
                  setShowDeleteModal(!showDeleteModal);
                }}
              >
                <img src={cancleIcon} className="" alt="delete icon" />
              </div>
            )}
            {hasPaymentApprovePer && (
              <div
                className={`action-btn approve-btn-bg ${
                  row.status !== 0 && "d-none"
                }`}
                onClick={() => handleAction(row.id, 1)}
              >
                <img src={tickIcon} className="" alt="edit icon" />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const updatedData = (filterData) => {
    const isStartDateExist = params.get("start");
    const isEndDateExist = params.get("end");
    let startDate = moment(dates[0].startDate).format("DD-MM-YYYY");
    let endDate = moment(dates[0].endDate).format("DD-MM-YYYY");
    let applyFilter = {
      status: status || undefined,
      start_date: isStartDateExist ? startDate : undefined,
      end_date:isEndDateExist ?  endDate : undefined,
    };
    if(filterData?.length == 0 && pageNo > 1) {
      params.set("page", pageNo -1);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      history(newUrl, { replace: true });
    }
    else {
      getOrderPayments(pageNo, searchVal, applyFilter);
    }
    }
  const data = getOrderPaymentApproveList?.map(item => {
    return {
      ...item,
    };
  });

  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleFilter = () => {
    let startDate = moment(dates[0].startDate).format("DD-MM-YYYY");
    let endDate = moment(dates[0].endDate).format("DD-MM-YYYY");

    let statusFilter;

    if (status == 1) {
      statusFilter = "Approved";
    } else if (status == 2) {
      statusFilter = "Rejected";
    } else if (status == 0) {
      statusFilter = "Pending";
    }

    if (status) {
      params.set("status", statusFilter);
    } else {
      params.delete("status");
    }
    if (searchVal) {
      params.set("str", searchVal);
    } else {
      params.delete("str");
    }

    if(params?.get("page")){
      params.delete("page");
    }
    params.set("start", startDate);
    params.set("end", endDate);
   

    // Update the URL with the new search parameters
    const newUrl = `${window.location.pathname}?${params.toString()}`;
   
    history(newUrl, { replace: true });
    // getOrderPayments(1, searchVal, applyFilter);
  };

  const handleAction = (id, type) => {
    toast.dismiss();
	const filterData = getOrderPaymentApproveList?.filter((item) => item.id !== id);
    setLoading(true);
    if (type === 2) {
      orderPaymentReject(id)
        .then(res => {
          setSelectedId("");
          setShowDeleteModal(false);
          PrimaryToast({ type: "success", message: res?.data?.message });
          updatedData(filterData);
        })
        .catch(err => {
			setLoading(false)
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        })
    } else if (type == 1) {
      orderPaymentApprove(id)
        .then(res => {
          setSelectedId("");
          setShowDeleteModal(false);
          PrimaryToast({ type: "success", message: res?.data?.message });
          updatedData(filterData);
        })
        .catch(err => {
          console.log(err);
		  setLoading(false)
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
        })
    }
  };


  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleFilter();
    }
  };

  return (
    <div className="page-content order-payment-approval-container">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to Reject this Order Payment?"}
          onConfirm={() => handleAction(selectedId, 2)}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3">
        <TopHeading heading={"Order Payment Approval"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex items-center">
              <div className="me-2">
                <PrimarySearch
                  placeholder={"Search by order number, name"}
                  handleKeyPress={handleKeyPress}
                  value={searchVal}
                  handleChange={handleSearch}
                />
              </div>
              <div className="d-flex flex-wrap">
                <select
                  className="primary-select-option ms-1"
                  name="status"
                  value={status || ""}
                  onChange={e => {
                    setStatus(e.target.value);
                  }}
                  onKeyPress={handleFilter}
                >
                  <option value={""}>Search By Status</option>
                  <option value={0}>Pending</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                  {/* <option value={3}>Freeze</option> */}
                </select>
                <div className="position-relative ms-3">
                  <div
                    className="form-control cursor-pointer rounded-full"
                    style={{ color: "#74788D", width: "max-content" }}
                    onClick={() => setShowCalendar(true)}
                  >
                    {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                    {moment(dates[0].endDate).format("DD MMM YYYY")}
                  </div>

                  {showCalendar && (
                    <>
                      <div
                        className="position-fixed top-0 start-0 w-100 h-100"
                        style={{ zIndex: "10" }}
                        onClick={() => setShowCalendar(false)}
                      ></div>
                      <DateRangePicker
                        dates={dates}
                        handleDatePicker={handleDatePicker}
                        handleSubmit={() => setShowCalendar(false)}
                        maxDate={new Date()}
                      />
                    </>
                  )}
                </div>
                <Button
                  color="primary"
                  className={`btn-rounded ms-3`}
                  onClick={handleFilter}
                >
                  Apply Filter
                </Button>
                {paramsLength > 0 && (
					<ResetButton />
                )}
              </div>
            </div>
          </div>
          <div className="table-responsive mt-3">
            <PrimaryTable
              keyField={"id"}
              responsive
              bordered={false}
              striped={false}
              columns={columns}
              data={data}
            />
          </div>
          {getOrderPaymentApproveList?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              setPageNo={setPageNo}
              pageNo={pageNo}
              pageCount={pageCount}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default OrderPaymentStatus;
