import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllStoreList = (page, search, status, start, end) => {
    console.log(status,start,end);
    return getApi({
        url: 'admin/seller/store',
        data: {
            is_admin: true,
            page: page || undefined,
            search: search || undefined,
            status: status || undefined,
            start_date:String(status).length > 0 ? start : undefined,
            end_date:String(status).length > 0 ? end : undefined 
        }
    }).then(res => res.data)
};




export const getSingleStoreData = (id) => {
    return getApi({
        url: `admin/seller/store/edit/${id}`,
        data: {
            is_admin: true
        }
    }).then(res => res.data)
};


export const getAllComments = (id,page) => {
    return getApi({
        url: `admin/seller/store/${id}/comments`,
        data: {
            is_admin: true,
            page: page || 1,
        }
    }).then(res => res.data)
};

export const cretaeNewComment = (comment, id) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/${id}/comments/store`,
        withCredentials: true,
        data: {
            is_admin: true,
            comment: comment,
        },
        headers: getHttpHeaders(),
    });

};

export const getAllPayoutMethods = (id, page) => {
    return getApi({
        url: `admin/seller/store/${id}/payoutMethods`,
        data: {
            is_admin: true,
            page: page || undefined
        }
    }).then(res => res.data)
}

export const getSinglePayout = (id) => {
    return getApi({
        url: `admin/seller/store/payoutMethods/${id}`,
        data: {
            is_admin: true
        }
    }).then(res => res.data)
};


export const deletePayoutMethod = (id) => {
    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/seller/store/payoutMethods/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });

};

export const updatePayoutMethod = (data,id) => {
    return axiosInstance({
        method: "PUT",
        url: `${siteApiUrl}admin/seller/store/payoutUpdate/${id}`,
        withCredentials: true,
        data: {
            ...data,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
}


export const getTransactionAdjustment = (id, page) => {
    return getApi({
        url: `admin/seller/store/${id}/transactionAdjustment`,
        data: {
            is_admin: true,
            page: page || undefined,
        }
    }).then(res => res.data)
};


export const createTransactionAdjustment = (data, id) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/${id}/transactionAdjustment/create`,
        withCredentials: true,
        data: {
            is_admin: true,
            ...data,
            effect: data?.effect || 0
        },
        headers: getHttpHeaders(),
    })

};



export const deleteSellerStore = (id) => {
    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/seller/store/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
};


export const updateSellerStore = (data, id) => {
    return axiosInstance({
        method: "PUT",
        url: `${siteApiUrl}admin/seller/store/update/${id}`,
        withCredentials: true,
        data: {
            ...data,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
};

export const storeRestrictions = (id, status, reason) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/restrict/${id}/${status}`,
        withCredentials: true,
        data: {
            reason: reason || undefined,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
};

// seller store logs

export const getLogsList = (id, page) => {
    return getApi({
        url: `admin/seller/store/logs/${id}`,
        data: {
            is_admin: true,
            page: page || undefined,
        }
    }).then(res => res.data)

};

export const payoutMethodLogs = (id, page) =>{
    return getApi({
        url: `admin/seller/store/payout-logs/${id}`,
        data: {
            is_admin: true,
            page: page || undefined,
        }
    }).then(res => res.data)
}

export const getDownloadTransactionCsv = (id) => {
    return axiosInstance(
        {
            url: `${siteApiUrl}admin/seller/store/transactions/${id}`,
            method:'get',
            params:{
                is_admin:true,
            },
            withCredentials:true,
            responseType:'text',
            headers: getHttpHeaders(),
        }

    );
}


export const sellerStoreLogin = (id,device_data) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/storeLogin/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
            device_data
        },
        headers: getHttpHeaders(),
    });
};

// seller comments apis

export const getAllSellerComments = (page,id) => {
    return getApi({
        url: `admin/seller/store/comments/${id}`,
        data: {
            is_admin: true,
            page:page || undefined,
        }
    }).then(res => res.data)
}

export const createSellerComments = (id,comment) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/comments-create/${id}`,
        withCredentials: true,
        data: {
            comment,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
};

export const deleteSellerComment = (id) => {
    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/seller/store/comment/${id}`,
        withCredentials: true,
        data: {
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
};

// Hyperwallet user 


export const getHyperwalletUser = (id) => {
    return getApi({
        url: `admin/seller/store/hyperwallet-user/${id}`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
};

export const updateHyperwalletUser = (fields,id) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/hyperwallet-user/update`,
        withCredentials: true,
        data: {
            ...fields,
            seller_id:id,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
};


// seller balances 


export const getAllSellerBalances = (id) => {
    return getApi({
        url: `admin/seller/store/${id}/sellerBalance`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
}

export const updateSellerBalance = (id, balance) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/${id}/sellerBalanceUpdt`,
        withCredentials: true,
        data: {
            ...balance,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    });
}


// delete adjustment 

export const deleteAdjusment = (tId) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/adjustment/destroy`,
        withCredentials: true,
        data: {
            transaction_id:tId,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    })
}

// Seller TransactionAdjustments

export const getSellerTransactionAdjustments = (id, page) => {
    return getApi({
        url: `admin/seller/store/adjustment-history/${id}`,
        data: {
            is_admin: true,
            page:page || 1,
        }
    }).then(res => res.data)
}

// Seller Balance Summary 

export const getSellerBalanceSummary = (id, page) => {
    return getApi({
        url: `admin/seller/store/sellerBalance-history/${id}`,
        data: {
            is_admin: true,
            page:page || 1,
        }
    }).then(res => res.data)
};


export const getSellerAdjustmentHistory = (id, page) => {
    return getApi({
        url: `admin/seller/transaction/adjustment/history/${id}`,
        data: {
            is_admin: true,
            page:page || 1,
        }
    }).then(res => res.data)
} 


// reset password link

export const resetPasswordLink = (id) => {
    return getApi({
        url: `admin/seller/store/reset-password/${id}`,
        data: {
            is_admin: true,
        }
    }).then(res => res.data)
}

// ban sellers by storename

export const banSellersByStoreName = (payload) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/multi-ban`,
        withCredentials: true,
        data: {
            ...payload,
            is_admin: true,
        },
        headers: getHttpHeaders(),
    })
}

//  adjustment seller balance

export const sellerBalanceAdjustment = (payload) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/transaction/adjustment`,
        withCredentials: true,
        data: {
            is_admin: true,
            ...payload,
        },
        headers: getHttpHeaders(),
    });
};

export const bulkBanSellersStores = (payload) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/multi-ban`,
        withCredentials: true,
        data: {
            is_admin: true,
            ...payload,
        },
        headers: getHttpHeaders(),
    });
};

export const syncMerchants = (sync_in, sellerId) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seller/store/sync-merchant`,
        withCredentials: true,
        data: {
            is_admin: true,
            sync_in:sync_in,
            seller_id:sellerId,
        },
        headers: getHttpHeaders(),
    });
};

// export const getStakeHolders = (id) => {
//     return getApi({
//         url: `admin/seller/store/stackholders/${id}`,
//         data: {
//             is_admin: true,
//         }
//     }).then(res => res.data)
// }


// seller login log

export const getAdminAndSellerLoginLogs = (id, page) => {
    return getApi({
        url: `admin/seller/store/authentication-logs/${id}`,
        data: {
            is_admin: true,
            page: page || 1,
        }
    }).then(res => res.data)
}