import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";


export const downloadKeywordsCsv = (keywords) => {
    return getApi({
        url: `admin/products/by-keywords`,
        data: {
            is_admin: true,
            keywords
        }
    }
    )
}

export const uploadSeoKeywords = (keywordsData) => {
    const formData = new FormData();
    console.log(Array.isArray(keywordsData.keywords),"check keywords data1");
    if(keywordsData?.name){
        formData.append("name", keywordsData.name);
    }
    if(keywordsData?.keywords){
        if (Array.isArray(keywordsData?.keywords)) {
            // Keywords is an array, append each item
            keywordsData?.keywords.forEach((keyword, index) => {
                formData.append(`keywords[]`, keyword);
            });
        } else if (keywordsData?.keywords instanceof File) {
            // Keywords is a CSV file, append the entire file
            formData.append("keywords", keywordsData?.keywords);
        }
    };

    formData.append("is_admin",true);
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seo/upload-keywords`,
        withCredentials: true,
        data:formData,
        headers: getHttpHeaders(),
    });
};

export const getAllSeoKeywords = (page, filter) => {
    return getApi({
        url: `admin/seo/keywords`,
        data: {
            is_admin: true,
            page:page || 1,
            ...filter,
        }
    }
    )
};

export const getAllJobsStatus = (page) => {
    return getApi({
        url: `admin/seo/jobs`,
        data: {
            is_admin: true,
            page:page || 1,
        }
    }
    )
};

export const removeSeoKeywords = (keyword_ids) => {
    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/seo/keyword/remove`,
        withCredentials: true,
        data:{
            is_admin:true,
            keyword_ids
        },
        headers: getHttpHeaders(),
    });
};

export const getProductIdsByKeyword = (id,page) => {
    return getApi({
        url: `admin/seo/keyword/${id}/pid`,
        data: {
            is_admin: true,
            page:page || 1,
        }
    }
    )
};

export const createCollectionByKeyword = (data) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seo/create-collection`,
        withCredentials: true,
        data:{
            is_admin:true,
            collections:data,
        },
        headers: getHttpHeaders(),
    });
};

export const downloadSeoKeywords = () => {
    return getApi({
        url: `admin/seo/keywords/export`,
        data: {
            is_admin: true,
        }
    }
    )
}

export const getRelatedGroups = (page, search) => {
    return getApi({
        url: `admin/seo/group/list`,
        data: {
            is_admin: true,
            page:page || 1,
            search : search || undefined,
        }
    }
    )
};

export const createRelatedGroups = (data) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seo/group/create`,
        withCredentials: true,
        data:{
            is_admin:true,
            ...data
        },
        headers: getHttpHeaders(),
    });
};

export const updateRelatedGroups = (data, id) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seo/group/update/${id}`,
        withCredentials: true,
        data:{
            is_admin:true,
            ...data
        },
        headers: getHttpHeaders(),
    });
};


export const geCollectionsAndCategories = (page,id) => {
    return getApi({
        url: `admin/seo/group/${id}/collections-and-categories`,
        data: {
            is_admin: true,
            page:page || 1,
        }
    }
    )
};

export const removeCollectionsAndCategories = (data,id) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/seo/group/${id}/collection-or-category/remove`,
        withCredentials: true,
        data:{
            is_admin:true,
            ...data
        },
        headers: getHttpHeaders(),
    });
};

export const getSingleRelatedGroup = (id) => {
    return getApi({
        url: `admin/seo/group/edit/${id}`,
        data: {
            is_admin: true,
        }
    }
    )
};

export const deleteRelatedGroups = (id) => {
    return axiosInstance({
        method: "DELETE",
        url: `${siteApiUrl}admin/seo/group/${id}`,
        withCredentials: true,
        data:{
            is_admin:true,
        },
        headers: getHttpHeaders(),
    });
};


export const  getAllFiltersCategories = () => {
    return getApi({
        url: `admin/filter/categories`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => (res.data))
}

export const getAllFilterCollections  = () => {
    return getApi({
        url: `admin/filter/collections`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => (res.data))
};

export const getAllFilterRelatedGroups  = () => {
    return getApi({
        url: `admin/filter/related-groups`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => (res.data))
}

export const exportRelatedGroups = (type) => {
    return getApi({
        url: `admin/seo/group/export`,
        data: {
            is_admin: true,
            type : type
        }
    }
    )
}