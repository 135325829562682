import React, { useState, useEffect } from 'react';
import TopHeading from 'components/Common/TopHeading';
import { Button, Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getSellersList } from "services/SellerGroupsServices";
import PrimaryTable from 'components/Common/PrimaryTable';
import ProgressPopup from 'components/Common/Popups/ProgressPopup';
import PrimarySearch from 'components/Common/PrimarySearch';
import PrimaryPagination from 'components/Common/Pagination/PrimaryPagination';
import { setPageTitle } from "utils/pageTitle";


let timmer;
function SellersList() {

	const history = useNavigate();
	const params = useParams();
	const { id } = params;

	const [sellersList, setSellerList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState();
	const [pageNo, setPageNo] = useState(1);
	const [searchVal, setSearchVal] = useState(null);
	

	useEffect(()=>{
		setPageTitle("Sellers List - Inspire Uplift")
	},[]);

	useEffect(() => {
		
		getAllSellers(pageNo,searchVal);

	}, [pageNo])


	const getAllSellers = (page,search) => {
		setLoading(true);
		getSellersList(id,page,search)
			.then(res => {
				setSellerList(res.data.Sellers?.data);
				setPageCount(res?.data?.Sellers?.last_page);

			})
			.catch(err => console.log(err))
			.finally(res => setLoading(false));
	}

	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			formatter: (cell, row) => (
				<div className='font-semi-bold'>
					#{row.id}
				</div>
			)
		},
		{
			dataField: 'name',
			text: 'Seller Name'
		},
		{
			dataField: 'shop_name',
			text: 'Store Name'
		},
		{
			dataField: 'created_at',
			text: 'Created At',
			formatter: (cell, row) => (
				<div className=''>
					{
						row?.created_at
					}
				</div>
			)
		},
	];

	const data = sellersList?.map((item) => {
		return {
			...item
		}
	})

	const handleSearch = (e) => {
		const search = e.target.value;
		setSearchVal(search);
		clearTimeout(timmer);
		timmer = setTimeout(() => {
			if(!e.target.value && pageNo > 1){
				setPageNo(1)
			}
			else {
				getAllSellers(null,search);
			}
		
		}, 400);
	}

	return (
		<div className='page-content'>
			{
				loading && <ProgressPopup label={"Loading..."} />
			}
			<div className='d-flex justify-content-between align-items-center mb-3'>
				<TopHeading heading={"Sellers List"} />
				<Button color='primary' onClick={() => { history('/seller/groups') }}>
					Go Back
				</Button>
			</div>
			<Card>
				<CardBody>
					<div className="mb-3">
						<PrimarySearch handleChange={handleSearch} />
					</div>
					<div>
						<PrimaryTable
							columns={columns}
							data={data}
							bordered={false}
							keyField="id"
						/>
					</div>
					{
						(sellersList?.length > 0 && pageCount > 0 ) && (
							<PrimaryPagination
								pageNo={pageNo} 
								pageCount={pageCount}
								setPageNo={setPageNo}
							/>
						)
					}
				</CardBody>
			</Card>
		</div>
	)
}

export default SellersList;