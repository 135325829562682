import { getApi } from "./BaseService";
import moment from "moment/moment";
import axiosInstance from "./axiosInstance";
import { siteApiUrl } from "./BaseService";
import { getHttpHeaders } from "./BaseService";

export const getOverTimeData = (start,end) => {
    return getApi({
        url:`admin/reports/overtime`,
        data:{
            is_admin:true,
            startDate:moment(start).format("MM/DD/YYYY") || undefined,
            endDate:moment(end).format("MM/DD/YYYY") || undefined,
        },

    }).then(res => (res.data))
};

export const getSalesBySeller = (page,start,end) => {
    return getApi({
        url:`admin/reports/seller_sales`,
        data:{
            is_admin:true,
            startDate:moment(start).format("MM/DD/YYYY") || undefined,
            endDate:moment(end).format("MM/DD/YYYY") || undefined,
            page:page || undefined,
        },

    }).then(res => (res.data))
};

export const getSalesByProducts = (page,start,end) => {
    return getApi({
        url:`admin/reports/product_sales`,
        data:{
            is_admin:true,
            startDate:moment(start).format("MM/DD/YYYY") || undefined,
            endDate:moment(end).format("MM/DD/YYYY") || undefined,
            page:page || undefined,
        },

    }).then(res => (res.data))
};


export const getSalesByChannel = (start,end) => {
    return getApi({
        url:`admin/reports/channel_sales`,
        data:{
            is_admin:true,
            startDate:moment(start).format("MM/DD/YYYY") || undefined,
            endDate:moment(end).format("MM/DD/YYYY") || undefined,
        },

    }).then(res => (res.data))
};


export const getProductPurchases = (start,end,page,search, sortOf, sortBy) => {
    return getApi({
        url:`admin/reports/product_sale`,
        data:{
            is_admin:true,
            start:start || undefined,
            end:end || undefined,
            page:page ||undefined,
            search:search || undefined,
            sort_column:sortOf == "cart" ? "add_to_cart" : sortOf,
            sort_type:sortBy,
        },

    }).then(res => (res.data))
};

export const getStoreStats = (pageNo,count,selectedRow ,countSign )=>{
    return getApi({
        url: `admin/seller/store/statistics`,
        data:{
            is_admin:true,
            page:pageNo || undefined,
            field:selectedRow || undefined,
            value:count ? Number(count) : undefined,
            sign:countSign == "greater" ? ">" : countSign == "smaller" ? "<" : countSign == "equal" ? "=" : undefined
        }
    }).then(res => (res.data))
}

export const getSalesByCountry = (start,end,page,search, sortOf, sortBy) => {
    return getApi({
        url:`admin/reports/country`,
        data:{
            is_admin:true,
            start:start || undefined,
            end:end || undefined,
            page:page ||undefined,
            search:search || undefined,
            sort_column:sortOf == "cart" ? "addToCart" : sortOf,
            sort_type:sortBy,
        },

    }).then(res => (res.data))
};

export const getSalesByReferrals = (start,end,page,search, sortOf, sortBy) => {
    return getApi({
        url:`admin/reports/referral`,
        data:{
            is_admin:true,
            start:start || undefined,
            end:end || undefined,
            page:page ||undefined,
            search:search || undefined,
            sort_column:sortOf == "cart" ? "addToCart" : sortOf,
            sort_type:sortBy,
        },

    }).then(res => (res.data))
};

export const exportProductViewsAndPurchases = (start, end) => {
    return axiosInstance({
        method: "POST",
        url: `${siteApiUrl}admin/reports/export_product_sale`,
        withCredentials: true,
        data: {
            start,
            end,
            is_admin:true,
            
        },
        headers: getHttpHeaders(),
    });
} 

// product views and purchases details
export const getProductPurchasesDetail = (start,end,page,product_id, sortOf, sortBy) => {
    return getApi({
        url:`admin/reports/product_sale_product`,
        data:{
            is_admin:true,
            start:start || undefined,
            end:end || undefined,
            page:page ||undefined,
            product_id,
            sort_column:sortOf == "cart" ? "addToCart" : sortOf,
            sort_type:sortBy,
        },

    }).then(res => (res.data))
};

// product sales by seller detail

export const getProductSalesBySeller = (start,end,page,seller_id, sortOf, sortBy) => {
    return getApi({
        url:`admin/reports/product_sale_seller`,
        data:{
            is_admin:true,
            start:start || undefined,
            end:end || undefined,
            page:page ||undefined,
            seller_id,
            sort_column:sortOf == "cart" ? "addToCart" : sortOf,
            sort_type:sortBy,
        },

    }).then(res => (res.data))
};


// sales by referrals detail

export const getSalesReferralsProductsDetail  = (start,end,page,referrer, sortOf, sortBy, filterType, filterValue) => {
    return getApi({
        url:`admin/reports/referral_products`,
        data:{
            is_admin:true,
            start:start || undefined,
            end:end || undefined,
            page:page ||undefined,
            referrer,
            sort_column:sortOf == "cart" ? "addToCart" : sortOf,
            sort_type:sortBy,
            filter_type:filterType == "greater" ? "greater_than" : filterType === "smaller" ? "less_than" : filterType == "equal" ? "equal"  : undefined,
            filter_value:filterValue || undefined,
         },

    }).then(res => (res.data))
};
