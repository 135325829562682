import React, { useState } from "react";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { Button, Nav, NavItem, NavLink } from "reactstrap";

const DateFilter = ({ width, setDate, defaultActiveTab = "4" }) => {

  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [rangeDates, setRangeDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDateRange, setShowDateRange] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }

    if (tab === "2") {
      const startOfWeek = moment().startOf("week").format("LL");
      const endOfWeek = moment().endOf("week").format("LL");
      setDate({
        startDate: new Date(startOfWeek),
        endDate: new Date(endOfWeek),
      });
    } else if (tab === "3") {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setDate({
        startDate: yesterday,
        endDate: yesterday,
      });
    } else if (tab === "4") {
      setDate({
        startDate: new Date(),
        endDate: new Date(),
      });
    }
  };

  const handleSelect = (ranges) => {
    setRangeDates([ranges.selection]);
  };

  return (
    <Nav pills justified className="date-filter-container position-relative">
      <NavItem>
        <NavLink
          className={activeTab === "1" ? "active" : ""}
          onClick={() => {
            toggleTab("1");
            setShowDateRange(true);
          }}
        >
          <i className="bx bx-chat font-size-20 d-sm-none" />
          <span className="d-none d-sm-block">Custom</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "2" ? "active" : ""}
          onClick={() => {
            toggleTab("2");
          }}
        >
          <i className="bx bx-group font-size-20 d-sm-none" />
          <span className="d-none d-sm-block">This Week</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "3" ? "active" : ""}
          onClick={() => {
            toggleTab("3");
          }}
        >
          <i className="bx bx-book-content font-size-20 d-sm-none" />
          <span className="d-none d-sm-block">Yesterday</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === "4" ? "active" : ""}
          onClick={() => {
            toggleTab("4");
          }}
        >
          <i className="bx bx-book-content font-size-20 d-sm-none" />
          <span className="d-none d-sm-block">Today</span>
        </NavLink>
      </NavItem>
      {showDateRange && activeTab === "1" && (
        <div
          style={{ left: `-125px` }}
          className={`position-absolute top-[60px] z-20  ${width ? width : ""} date-range-container right-0 sm:overflow-visible overflow-auto`}
        >
          <DateRangePicker
            onChange={handleSelect}
            ranges={rangeDates}
            direction="horizontal"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            inputRanges={[]}
            maxDate={new Date()}
          />
          <Button
            onClick={() => {
              setDate(rangeDates[0]);
              setShowDateRange(false);
            }}
            style={{
              position: "absolute",
              bottom: "10px",
              left: "20px",
            }}
            color="primary"
            className="nav-bg text-white min-w-[100px] max-w-[170px] text-base py-1 px-4 rounded absolute top-[30%] right-4 xl:bottom-4 xl:left-8 xl:right-[unset] xl:top-[unset] break-words"
          >
            Submit
          </Button>
        </div>
      )}
    </Nav>
  );
};

export default DateFilter;
