import React from "react";
import { Button } from "reactstrap";

const ProductApprovedInput = ({
  setSeller,
  seller,
  handleClick,
  loading,
  setActionType,
  setShowDeleteModal,
  handleProductAction,
  selectedRowKeys

}) => {
	console.log(selectedRowKeys,"chcek selected",loading || seller?.length == 0 || selectedRowKeys?.length == 0);
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center product-approved-input-container">
        <input
          type="text"
          className="border-none outline-none py-2 px-3"
          placeholder="Type seller ID"
          name="seller"
          value={seller}
          onChange={e => {
            setSeller(e.target.value);
          }}
        />
        <Button
          color="danger"
          disabled={loading || ( seller?.length == 0 && selectedRowKeys?.length == 0)}
          className="py-2 px-3 rounded-0"
          onClick={() => {
            setActionType(seller?.length > 0 ? "reject" :'');
			setShowDeleteModal(true);
          }}
        >
          Reject
        </Button>
        <Button
          color="primary "
          disabled={loading || ( seller?.length == 0 && selectedRowKeys?.length == 0)}
          className="py-2 px-3 rounded-right rounded-0"
          onClick={()=>{
			if(seller?.length == 0) {
				handleProductAction(selectedRowKeys,2)
			}
			else {
				handleClick(1)
			}
			
		}}
        >
          Approve
        </Button>
      </div>
    </div>
  );
};
export default ProductApprovedInput;
