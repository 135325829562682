import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button, Row } from "reactstrap";
import PrimarySelect from "../PrimarySelect";

function ProductRejectPopup({
  handleChange,
  title,
  isOpen,
  handleClose,
  handleSave,
  loading,
}) {
  return (
    <PrimaryPopup
      title={"Select Reject Reason"}
      isOpen={isOpen}
      className={"d-flex justify-content-center align-items-center"}
      isScrollable={true}
      handleClick={handleClose}
    >
      <Row className="mb-3 align-items-center">
        <div className="h4 mb-3">{title}</div>
        <PrimarySelect
          handleChange={handleChange}
          name="reason"
          label={"Reason"}
          isRequired={true}
          options={[
            {
              name: "Select Option to Reject this product",
              value: "",
            },
            {
              name: "Alcohol, Tobacco, Drugs, Drug Paraphernalia, and Medical Drugs",
              value:
                "Alcohol, Tobacco, Drugs, Drug Paraphernalia, and Medical Drugs",
            },
            {
              name: "Animal Products and Human Remains",
              value: "Animal Products and Human Remains",
            },
            {
              name: "Dangerous Items: Hazardous Materials, Recalled Items, and Weapons",
              value:
                "Dangerous Items: Hazardous Materials, Recalled Items, and Weapons",
            },
            {
              name: "Hate Items: Items that Promote, Support, or Glorify Hatred",
              value:
                "Hate Items: Items that Promote, Support, or Glorify Hatred",
            },
            {
              name: "Illegal Items, Items Promoting Illegal Activity, and Highly Regulated Items",
              value:
                "Illegal Items, Items Promoting Illegal Activity, and Highly Regulated Items",
            },
            {
              name: "Internationally Regulated Items",
              value: "Internationally Regulated Items",
            },
            {
              name: "Pornography and Mature Content",
              value: "Pornography and Mature Content",
            },
            {
              name: "Violent Items: Items that Promote, Support, or Glorify Violence",
              value:
                "Violent Items: Items that Promote, Support, or Glorify Violence",
            },
            {
              name: "Violation of Inspire Uplift policy",
              value: "Violation of Inspire Uplift policy",
            },
          ]}
        />
      </Row>
      <div className="d-flex  justify-content-end">
        <Button
          color="primary"
          type="button"
          onClick={handleSave}
          disabled={loading}
        >
          Submit
        </Button>
      </div>
    </PrimaryPopup>
  );
}

export default ProductRejectPopup;
