import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import plusIcon from "../../assets/images/plus-icon.svg";
import deleteIcon from "../../assets/images/delete-icon-white.svg";
import editIcon from "../../assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import PrimarySearch from "components/Common/PrimarySearch";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryTable from "components/Common/PrimaryTable";
import { getAllGiftCards, deleteGiftCard } from "services/GiftCardsService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";

function GiftCardsListing() {
  const [loading, setLoading] = useState(false);
  const [giftCardsData, setGiftCardsData] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchVal, setSearch] = useState(null);
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const params = new URLSearchParams(window?.location?.search);
  const history = useNavigate();

  useEffect(()=>{
		setPageTitle("Gift Cards - Inspire Uplift")
	},[]);

  useEffect(() => {
    const page = params?.get('page') || undefined;
    const search = params?.get("search") || undefined;
    setPageNo(page || 1);
    setSearch(search || '');
    setLoading(true);
    getGiftCards(page, search);
  }, [window?.location?.search]);

  const getGiftCards = (page, search) => {
    getAllGiftCards(page, search)
      .then(res => {
        setGiftCardsData(res?.data?.GiftCard?.data);
        setPageCount(res?.data?.GiftCard?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const handleDelete = () => {
	const filterData = giftCardsData?.filter(item => item?.id !== selectedId);
    setLoading(true);
    deleteGiftCard(selectedId)
      .then(res => {
        if (res.status === 200) {
			if(filterData?.length === 0 && pageNo > 1) {
				params?.set('page', pageNo - 1);
				let newUrl = `${window?.location?.pathname}?${params?.toString()}`;
				history(newUrl, { replace: true });
			}
			else {
				getGiftCards(pageNo,searchVal);
			}
          setShowDeleteModal(false);
          setSelectedId();
        }
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return (
          <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
        );
      },
    },
    {
      dataField: "amount",
      text: "Initial Amount/Balance",
      formatter: (cell, row) => (
        <div>
          {row.amount}/{row.balance}
        </div>
      ),
    },
    {
      dataField: "recipient_name",
      text: "Recipient Name",
    },
    {
      dataField: "is_active",
      text: "Availability",
      formatter: (cell, row) => {
        let status;
        switch (row.is_active) {
          case 0:
            status = "Inactive";
            break;

          case 1:
            status = "Active";
            break;

          default:
            status = "Active";
        }
        return (
          <div
            className={`status ${
              status === "Active" ? "success-status" : "danger-status"
            } w-max-content`}
          >
            {status}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cell, row) => {
        return <div>{row.created_at}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex align-items-center">
          {permissions?.includes("gift-edit") && (
            <div
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={() => history(`/gift-cards/edit/${row.id}`)}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
          )}
          {permissions?.includes("gift-delete") && (
            <div
              className="action-btn delete-btn-bg cursor-pointer"
              onClick={() => {
                setSelectedId(row.id);
                setShowDeleteModal(true);
              }}
            >
              <img src={deleteIcon} className="" alt="delete icon" />
            </div>
          )}
        </div>
      ),
    },
  ];

  const data = giftCardsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
		if (params?.get("page")) {
		  params?.delete("page");
		}
		if (searchVal) {
		  params?.set("search", searchVal);
		} else {
		  params?.delete("search");
		}
  
		history(`/gift-cards/list?${params?.toString()}`);
	}
};

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this gift card?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"All Gift Cards"} />
        {permissions?.includes("gift-create") && (
          <Button
            className="rounded"
            color="primary"
            onClick={() => {
              history("/gift-cards/create");
            }}
          >
            <img alt="plus icon" src={plusIcon} className="me-2" />
            Create New Gift Card
          </Button>
        )}
      </div>
      <Card>
        <CardBody>
          <PrimarySearch
            placeholder={"Search by recipient name"}
            handleChange={handleSearch}
            value={searchVal}
            handleKeyPress={handleKeyPress}
          />
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="id"
            />
            {giftCardsData?.length > 0 && pageCount > 1 && (
              <PrimaryPagination
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default GiftCardsListing;
