import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Row, Col } from "reactstrap";
import CustomerOrdersTabel from "components/Customers/OrdersTabel";
import PrimaryCard from "components/Common/PrimaryCard";

import totalOrdersImage from "../../assets/images/total-orders.svg";
import refundOrdersImage from "../../assets/images/refund-orders.svg";
import totalRevenueImage from "../../assets/images/total-revenue.svg";
import { useParams } from "react-router-dom";
import {
  getCustomerDetail,
  getCustomerOrdersList,
} from "services/CustomerServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import VipImage from "assets/images/VIP.png";

function CustomerDetail() {
  const [customerDetail, setCustomerDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [customerOrders, setCustomerOrders] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Customer Detail - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getCustomerDetail(id)
      .then(res => setCustomerDetail(res.data))
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    getCustomerOrdersList(id, pageNo)
      .then(res => {
        setCustomerOrders(res.data.data);
        setPageCount(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, [pageNo]);

  const data = customerOrders?.map(item => {
    return {
      ...item,
    };
  });

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Customer Profile"} />
      </div>
      <Row>
        <Col sm="4">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="name-avatar  text-light">
                  {customerDetail?.customer?.first_name
                    .charAt(0)
                    ?.toUpperCase()}
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="font-medium ms-3 mb-0">
                    {customerDetail?.customer?.first_name}{" "}
                    {customerDetail?.customer?.last_name}
                  </h4>
                  {customerDetail?.customer?.subscription_active == 1 && (
                    <img
                      src={VipImage}
                      alt="vip customer"
                      className="ms-2"
                      height={22}
                      width={34}
                    />
                  )}
                </div>
              </div>
              {customerDetail?.customer?.email ? (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="font-semi-bold text-secondary">Email:</div>
                  <p className="text-secondary mb-0">
                    {customerDetail?.customer?.email}
                  </p>
                </div>
              ) : null}
              {customerDetail?.customer?.gender ? (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="font-semi-bold text-secondary">Gender:</div>
                  <p className="text-secondary mb-0">
                    {customerDetail?.customer?.gender}
                  </p>
                </div>
              ) : null}
              {customerDetail?.customer?.dob ? (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="font-semi-bold text-secondary">
                    Date of Birth:
                  </div>
                  <p className="text-secondary mb-0">
                    {customerDetail?.customer?.dob}
                  </p>
                </div>
              ) : null}
              {customerDetail?.customer?.note ? (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="font-semi-bold text-secondary">Note:</div>
                  <p className="text-secondary mb-0">
                    {customerDetail?.customer?.note}
                  </p>
                </div>
              ) : null}
              {customerDetail?.customer?.phone ? (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="font-semi-bold text-secondary">
                    Phone Number:
                  </div>
                  <p className="text-secondary mb-0">
                    {customerDetail?.customer?.phone}
                  </p>
                </div>
              ) : null}
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="font-semi-bold text-secondary">Status:</div>
                <p
                  className={`mb-0 status w-max-content ${
                    customerDetail?.customer?.is_active === 1
                      ? "success-status"
                      : customerDetail?.customer?.is_active === 0 || customerDetail?.customer?.is_active === 2 
                      ? "danger-status"
                      : ""
                  }`}
                >
                  {customerDetail?.customer?.is_active === 1
                    ? "Active"
                    : customerDetail?.customer?.is_active === 0
                    ? "Inactive"

                    :  customerDetail?.customer?.is_active === 2 ? "Block" :""}
                </p>
              </div>
              {customerDetail?.customer?.currency ? (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="font-semi-bold text-secondary">Currency:</div>
                  <p className="text-secondary mb-0">
                    {customerDetail?.customer?.currency}
                  </p>
                </div>
              ) : null}
              {customerDetail?.customer?.created_at ? (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="font-semi-bold text-secondary">
                    Joined At:
                  </div>
                  <p className="text-secondary mb-0">
                    {customerDetail?.customer?.created_at
                      ? formatDate(customerDetail?.customer?.created_at)
                      : ""}
                  </p>
                </div>
              ) : null}
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="font-semi-bold text-secondary">
                  Subscription Status:
                </div>
                <p
                  className={`mb-0 status w-max-content ${
                    customerDetail?.customer?.subscription_active === 1
                      ? "success-status"
                      : "danger-status"
                  }`}
                >
                  {customerDetail?.customer?.subscription_active === 1
                    ? "Active"
                    : "Inactive"}
                </p>
              </div>
              {customerDetail?.customer?.subscription_active == 1 && (
                <div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="font-semi-bold text-secondary">
                      Subscribed On:
                    </div>
                    <p className={`text-secondary mb-0`}>
                      {customerDetail?.customer?.subscription_date
                        ? formatDate(
                            customerDetail?.customer?.subscription_date
                          )
                        : ""}
                    </p>
                  </div>
                </div>
              )}
              {customerDetail?.customer?.last_login && (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="font-semi-bold text-secondary">
                    Last Login:
                  </div>
                  <p className="text-secondary mb-0">
                    {customerDetail?.customer?.last_login}
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col sm="8">
          <Row>
            <Col sm="4">
              <PrimaryCard
                title="Total Orders"
                count={
                  customerDetail?.customerOrderCount
                    ? customerDetail?.customerOrderCount
                    : 0
                }
                icon={totalOrdersImage}
              />
            </Col>
            <Col sm="4">
              <PrimaryCard
                title="Refunded Orders"
                count={
                  customerDetail?.refundOrdersCount
                    ? customerDetail?.refundOrdersCount
                    : 0
                }
                icon={refundOrdersImage}
              />
            </Col>
            <Col sm="4">
              <PrimaryCard
                title="Total Revenue"
                count={`$${
                  customerDetail?.customer?.total_spent
                    ? customerDetail?.customer?.total_spent
                    : 0
                }`}
                icon={totalRevenueImage}
              />
            </Col>
          </Row>
          <div>
            <CustomerOrdersTabel
              pageCount={pageCount}
              data={data}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerDetail;
