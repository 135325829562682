import React, { useState, useEffect } from "react";

import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import PrimaryInput from "components/Common/PrimaryInput";
import SwitchButton from "components/Common/SwitchButton";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import PrimarySelect from "components/Common/PrimarySelect";
import DateRangeSelect from "components/Common/DateRangePicker";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import moment from "moment";
import {
  getSinglePromotion,
  updatePromotion,
} from "services/PromotionsService";
import PrimaryImageUploader from "components/Common/PrimaryImageUploder";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import RichTextEditor from "components/Common/TextEditor/RichTextEditor";

function EditPromotion() {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [description, setDescription] = useState();
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [media, setMedia] = useState();
  const [mobileMedia, setMobileMedia] = useState();
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    setPageTitle("Edit Promotion - Inspire Uplift");
  }, []);

  const history = useNavigate();
  const { id } = useParams();

  function isJSONObject(data) {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    setLoading(true);
    getSinglePromotion(id)
      .then(res => {
        let parsedData;
        if (isJSONObject(res?.data?.style)) {
          parsedData = JSON.parse(res?.data?.style);
          // use the parsed data as a JavaScript object
        } else {
          parsedData = res?.data?.style;
        }

        setFields({
          ...res?.data,
          hidden_title: res?.data?.hidden_title === 0 ? true : false,
          is_hidden: res?.data?.is_hidden === 0 ? true : false,
          style: parsedData?.color || "",
          productIds: res?.data?.products_ids || "",
        });
        setDescription(res?.data?.description);

        setDates([
          {
            key: "selection",
            startDate: new Date(res?.data?.start_at) || new Date(),
            endDate: new Date(res?.data?.end_at) || new Date(),
          },
        ]);
        setMedia({
          preview: res?.data?.media_id || "",
          name: "image",
        });
        setMobileMedia({
          preview: res?.data?.mobile_media_id || "",
          name: "image",
        });
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    const regex = /^[0-9,]*$/;
    if (name === "productIds") {
      if (!regex.test(value)) {
        return false;
      } else {
        fieldsObj[name] = value;
      }
    } else {
      fieldsObj[name] = value;
    }
    setFields(fieldsObj);
  };

  const handleEditor = (event, name) => {
    if (name === "description") {
      setDescription(event);
    }
  };

  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = {
      ...fields,
      description: description,
      start_at: moment(dates[0].startDate).format("DD-MM-YYYY"),
      end_at: moment(dates[0].endDate).format("DD-MM-YYYY"),
      media:
        media?.name !== "image" && media?.name !== "delete_media_img"
          ? media
          : undefined,
      mobile_media:
        mobileMedia?.name !== "image" &&
        mobileMedia?.name !== "delete_media_img"
          ? mobileMedia
          : undefined,
      media_id_delete:
        media?.name == "delete_media_img" ? media.url : undefined,
      mobile_media_id_delete:
        mobileMedia?.name === "delete_media_img" ? mobileMedia?.url : undefined,
    };
    toast.dismiss();
    setLoading(true);
    updatePromotion(data, id)
      .then(res => {
        if (res.status === 200) {
          history("/marketing/promotions");
        }
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err, err?.response?.data?.message[0]);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading...."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <TopHeading heading={"Edit Promotion"} />
          <div className="d-flex">
            <Button className="me-2" onClick={() => history(-1)}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </div>
        </div>
        <Card>
          <CardBody className="w-50">
            <h4 className="font-semi-bold">Promotion Information</h4>
            <div className="mb-3">
              <PrimaryInput
                label={"Title"}
                isRequired
                name="title"
                placeholder={"Enter Promotion Title"}
                value={fields?.title || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmail4">
                Title Visibility<span className="primary-text">*</span>
              </label>
              <SwitchButton
                checked={fields.hidden_title === true ? true : false}
                label={fields.hidden_title === true ? "Active" : "Inactive"}
                name="hidden_title"
                value={fields?.hidden_title}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Slug"}
                isRequired
                name="slug"
                placeholder={"Enter Promotion Slug"}
                value={fields?.slug || ""}
                handleChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <PrimarySelect
                label={"Position"}
                name="position"
                value={fields?.position || ""}
                isRequired={true}
                handleChange={handleChange}
                options={[
                  {
                    name: "No Position",
                    value: "",
                  },
                  {
                    name: "Main Slider",
                    value: "main-slider",
                  },
                  {
                    name: "Promo Position One ",
                    value: "position-one",
                  },
                  {
                    name: "Promo Position Two",
                    value: "position-two",
                  },
                  {
                    name: "Promo Position Three",
                    value: "position-three",
                  },
                ]}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputEmail4">Description</label>
              <RichTextEditor
                value={description}
                disablePlugins={
                  "about,paste-storage,add-new-line,ai-assistant,color,copyformat,drag-and-drop,drag-and-drop-element,dtd,file,fullsize,hr,iframe,indent,key-arrow-outside,line-height,media,mobile,placeholder,powered-by-jodit,preview,print,resize-cells,resize-handler,search,select-cells,source,speech-recognize,spellcheck,sticky,symbols,table-keyboard-navigation,video,wrap-nodes,xpath"
                }
                buttons={
                  "bold,underline,italic,strikethrough,ul,ol,paragraph,link,left, right, center"
                }
                name={"description"}
                handleChange={e => handleEditor(e)}
              />
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"SEO Title"}
                isRequired
                handleChange={handleChange}
                value={fields?.seo_title || ""}
                name="seo_title"
                placeholder={"Enter SEO Title"}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="seoDescription">
                SEO Description<span className="primary-text">*</span>
              </label>
              <textarea
                rows={"4"}
                type="text"
                value={fields?.seo_description || ""}
                className="form-control"
                id="seoDescription"
                name="seo_description"
                onChange={handleChange}
                placeholder="Enter Description"
              />
            </div>
            <div className="mb-3 position-relative">
              <PrimaryImageUploader
                path="promotion"
                label={"Media Image"}
                getFiles={media}
                setFiles={setMedia}
                isRequired
              />
            </div>
            <div className="mb-3 position-relative">
              <PrimaryImageUploader
                path={"promotion"}
                label={"Mobile Media Image"}
                getFiles={mobileMedia}
                setFiles={setMobileMedia}
                isRequired
              />
            </div>
            <div className="mb-3 position-relative">
              <label htmlFor="mobileFile" className="form-label">
                Date
              </label>
              <div
                className="form-control cursor-pointer"
                onClick={() => setShowCalendar(true)}
              >
                {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                {moment(dates[0].endDate).format("DD MMM YYYY")}
              </div>

              {showCalendar && (
                <>
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{ zIndex: "10" }}
                    onClick={() => setShowCalendar(false)}
                  ></div>
                  <DateRangeSelect
                    dates={dates}
                    handleDatePicker={handleDatePicker}
                    handleSubmit={() => setShowCalendar(false)}
                  />
                </>
              )}
            </div>
            <div className="mb-3">
              <PrimaryInput
                label={"Action Button Name"}
                type="text"
                name="action"
                placeholder={"Enter button name"}
                handleChange={handleChange}
                value={fields?.action || ""}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="inputEmail4">
                Visibility<span className="primary-text">*</span>
              </label>
              <SwitchButton
                checked={fields.is_hidden === true ? true : false}
                label={fields.is_hidden === true ? "Active" : "Inactive"}
                name="is_hidden"
                value={fields?.is_hidden}
                handleChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="inputEmail4">
                Product ID&apos;s<span className="primary-text">*</span>
              </label>
              <textarea
                rows={"4"}
                type="text"
                name="productIds"
                value={fields?.productIds || ""}
                onChange={handleChange}
                className="form-control"
                id="inputEmail4"
                placeholder="Enter product IDs"
              />
            </div>

            <label>Color Picker</label>
            <div className="mb-3 d-flex border-gray rounded overflow-hidden">
              <input
                type={"text"}
                className="w-100 border-0 p-1"
                value={fields?.style || "#0000"}
              />
              <div
                style={{ width: "36px" }}
                className="border-start bg-light  p-1 d-flex justify-content-center align-items-center"
              >
                <input
                  type="color"
                  name="style"
                  placeholder={"Enter Promotion Slug"}
                  onChange={handleChange}
                  value={fields?.style || "#0000"}
                  className="border-0 p-0"
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditPromotion;
