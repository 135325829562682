import React, { useState, useEffect, useMemo } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../../assets/images/delete-icon-white.svg";
import editIcon from "../../../assets/images/edit-icon-white.svg";
import tickIcon from "assets/images/tick-icon.svg";
import cancleIcon from "assets/images/cancle-icon.svg";
import PrimaryTable from "components/Common/PrimaryTable";
import { getALlTasks, deleteTask, comoeleteTask } from "services/TaskServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { setPageTitle } from "utils/pageTitle";
import useToggle from "hooks/useToggle";
import PrimarySearch from "components/Common/PrimarySearch";
import { getAllEmployees } from "services/TaskServices";
import Select from "react-select";
import ResetButton from "components/Common/ResetButton";

const customStyles = {
  control: provided => ({
    ...provided,
    borderRadius: "999px",
  }),
};

const TaskList = () => {
  const [tasksData, setTasksData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState("");
  const [popupType, setPopupType] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [options, setOptions] = useState();
  const [assignedEmployee, setAssignedEmployee] = useState([]);
  const [assignedByEmployee, setAssignedByEmployee] = useState([]);
  const params = new URLSearchParams(window?.location?.search);

  const getEmployees = async () => {
    try {
      const res = await getAllEmployees();
      const customTypes = res?.data?.map(item => ({
        label: item.name,
        value: item.id,
      }));
      setOptions(customTypes);
      return customTypes; // Return the fetched options
    } catch (error) {
      console.log(error);
    }
  };

  const getOptions = useMemo(() => {
    return getEmployees();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("Tasks Dashboard - Inspire Uplift");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const employeesList = await getOptions;
      const page = params?.get("page") || undefined;
      setPageNo(page || 1);
      const searchParam = params?.get("search") || undefined;
      setSearch(searchParam || '');
      const statusParam = params?.get("status") || undefined;
      setStatus(statusParam || '');
      const assignByParam = params?.get("assigned_by") || undefined;
      let findAssignBy;
      if (assignByParam) {
        findAssignBy = employeesList?.find(item => item.label == assignByParam);
        setAssignedByEmployee(findAssignBy);
      }
      else {
        setAssignedByEmployee([]);
      }
      const assignToParam = params?.get("assigned_to") || undefined;
      let findAssignTo;
      if (assignToParam) {
        findAssignTo = employeesList?.find(item => item.label == assignToParam);
        setAssignedEmployee(findAssignTo);
      }
      else {
        setAssignedEmployee([]);
      }

      setLoading(true);
      getALlTasks(
        page,
        searchParam,
        statusParam,
        findAssignTo?.value,
        findAssignBy?.value
      )
        .then(res => {
          setTasksData(res?.data?.data?.data);
          setPageCount(res?.data?.data?.last_page);
        })
        .catch(err => console.log(err))
        .finally(res => setLoading(false));
    };
    fetchData();
  }, [window?.location?.search]);

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "title",
      text: "Title",
      formatter: (cell, row) => (
        <div className="font-semi-bold">
          {row.title?.length >= 50
            ? `${row?.title?.slice(0, 50)}...`
            : row.title}
        </div>
      ),
    },
    {
      dataField: "assigned_by",
      text: "Assigned By",
      formatter: (cell, row) => {
        return <div>{row?.assigned_by?.name}</div>;
      },
    },
    {
      dataField: "assigned_to",
      text: "Assigned To",
      formatter: (cell, row) => {
        let assignedTask = row?.assigned_to?.map((item, index) => (
          <div key={index}>
            {item.name} {index === row?.assigned_to?.length - 1 ? "" : ", "}
          </div>
        ));
        return <div>{assignedTask}</div>;
      },
    },
    {
      dataField: "priority",
      text: "Priority",
      formatter: (cell, row) => (
        <div
          className={`status ${
            row.priority === "low"
              ? "success-status"
              : row.priority === "medium"
              ? "warning-status"
              : row.priority === "high"
              ? "danger-status"
              : ""
          } w-max-content`}
        >
          {row.priority}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`status ${
            row.status == "completed"
              ? "success-status"
              : row.status === "pending"
              ? "warning-status"
              : ""
          }  w-max-content`}
        >
          {row.status}
        </div>
      ),
    },
    {
      dataField: "created_on",
      text: "Created On",
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          {row?.status !== "completed" && (
            <div
              className="action-btn approve-btn-bg me-1"
              onClick={e => {
                e.stopPropagation();
                setShowDeleteModal(true);
                setPopupType("complete");
                setSelectedId(row.id);
              }}
            >
              <img src={tickIcon} className="" alt="edit icon" />
            </div>
          )}
          {row?.status !== "completed" && (
            <div
              className="action-btn edit-icon-bg me-1 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                navigate(`/employees/tasks/edit/${row.id}`);
              }}
            >
              <img src={editIcon} className="" alt="edit icon" />
            </div>
          )}
          <div
            className="action-btn delete-btn-bg cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              setShowDeleteModal(true);
              setPopupType("delete");
              setSelectedId(row.id);
            }}
          >
            <img src={deleteIcon} className="" alt="delete icon" />
          </div>
        </div>
      ),
    },
  ];

  const data = tasksData?.map((item, index) => {
    return {
      ...item,
      updates: 2,
      index: index + 1,
    };
  });

  const handleDelete = () => {
    let ids = selectedId ? [selectedId] : selectedRowKeys;
    let filterArr;
    if (selectedId) {
      filterArr = tasksData?.filter(item => item.id !== selectedId);
    } else {
      filterArr = tasksData.filter(item => !selectedRowKeys.includes(item.id));
    }
    setLoading(true);
    deleteTask(ids)
      .then(res => {
        if (pageNo > 1 && filterArr.length === 0) {
          params.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          navigate(newUrl, { replace: true });
        } else {
          setLoading(false)
          setTasksData(filterArr);
        }
        setSelectedRowKeys([]);
        setSelectedId("");
        setShowDeleteModal(false);
        setPopupType("");
      })
      .catch(err => {
        setLoading(false)
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })

  };

  const handleOnSelectAll = (isSelected, rows) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.id));
    } else {
      setSelectedRowKeys([]);
    }
  };

  const nonSelected = tasksData?.map(item => {
    if (item.status === "completed") {
      return item.id;
    }
  });

  const selectRow = {
    mode: "checkbox",
    nonSelectable: nonSelected,
    onSelectAll: handleOnSelectAll,
    nonSelectableClasses: "hide-checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, row.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
      }
    },
    selected: selectedRowKeys,
  };

  const handleComplete = () => {
    let ids = selectedId ? [selectedId] : selectedRowKeys;
    let filterArr;
    if (selectedId) {
      filterArr = tasksData?.filter(item => item.id !== selectedId);
    } else {
      filterArr = tasksData.filter(item => !selectedRowKeys.includes(item.id));
    }
    setLoading(true);
    comoeleteTask(ids)
      .then(res => {
        PrimaryToast({ type: "success", message: res?.data?.messgae });
        setSelectedRowKeys([]);
        setSelectedId("");
        setPopupType("");
        setShowDeleteModal(false);
        getALlTasks(
          pageNo,
          search,
          status,
          assignedEmployee?.value,
          assignedByEmployee?.value
        )
          .then(res => {
            if (pageNo > 1 && filterArr.length === 0) {
                params.set("page", pageNo - 1);
                let newUrl = `${window?.location?.pathname}?${params.toString()}`;
                navigate(newUrl, { replace: true });
              } else {
                setTasksData(res?.data?.data?.data);
                setPageCount(res?.data?.data?.last_page);
            }

          })
          .catch(err => console.log(err))
          .finally(res =>    setLoading(false));
      })
      .catch(err => {   
        setLoading(false);
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.messgae });
      })
  };

  const handleFilter = () => {
    if (search) {
      params.set("search", search);
    } else {
      params.delete("search");
    }
    if (status) {
      params.set("status", status);
    } else {
      params.delete("status");
    }

    if (assignedEmployee?.value) {
      params.set("assigned_to", assignedEmployee?.label);
    } else {
      params.delete("assigned_to");
    }

    if (assignedByEmployee?.value) {
      params.set("assigned_by", assignedByEmployee?.label);
    } else {
      params.delete("assigned_by");
    }

    params.delete("page");

    navigate(`?${params.toString()}`);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {showDeleteModal && (
        <DeleteModal
          title={
            popupType === "delete"
              ? "Are you sure you want to delete this task?"
              : "Are you sure you want to complete this task?"
          }
          onConfirm={
            popupType === "delete"
              ? () => handleDelete()
              : () => handleComplete()
          }
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
            setPopupType("");
          }}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <TopHeading heading={"Tasks Dashboard"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex flex-wrap">
            <div className="">
              <PrimarySearch
                placeholder={"search by title"}
                value={search || ""}
                handleChange={e => setSearch(e.target.value)}
                handleKeyPress={e => {
                  if (e.key == "Enter") {
                    handleFilter();
                  }
                }}
              />
            </div>
            <div style={{ minWidth: "200px", marginLeft: "12px" }}>
              <Select
                isMulti={false}
                options={options}
                onChange={item => setAssignedByEmployee(item)}
                value={assignedByEmployee}
                name={"payout_method"}
                className="react-select-container"
                styles={customStyles}
                placeholder={"Search by assigned by"}
              />
            </div>
            <div style={{ minWidth: "200px", marginLeft: "12px" }}>
              <Select
                isMulti={false}
                options={options}
                onChange={item => setAssignedEmployee(item)}
                value={assignedEmployee}
                name={"payout_method"}
                className="react-select-container"
                styles={customStyles}
                placeholder={"Search by assigned to"}
              />
            </div>
            <div className="">
              <select
                style={{ width: "155px" }}
                className="primary-select-option ms-2"
                name="status"
                value={status || ""}
                onKeyPress={e => {
                  if (e.key == "Enter") {
                    console.log("working");
                    handleFilter();
                  }
                }}
                onChange={e => {
                  setStatus(e.target.value);
                }}
              >
                <option value={""}>Search By Status</option>
                <option value="pending"> Pending </option>
                <option value="complete"> Complete </option>
              </select>
            </div>
            <Button
              className="rounded-full me-2 ms-2"
              color="primary"
              onClick={handleFilter}
            >
              Apply Filter
            </Button>
            {params?.toString()?.length > 0 && (
              <ResetButton />
            )}
          </div>
          {selectedRowKeys?.length > 0 && (
            <div className="d-flex align-items-center justify-content-end mt-3">
              <Button
                className="rounded-full me-2"
                onClick={() => {
                  setShowDeleteModal(true);
                  setPopupType("delete");
                }}
                color="danger"
              >
                <img src={cancleIcon} alt="tick icon" className="pe-2" />
                Delete
              </Button>
              <Button
                className="rounded-full me-2 cursor-pointer"
                color="primary"
                onClick={() => {
                  setShowDeleteModal(true);
                  setPopupType("complete");
                }}
              >
                <img src={tickIcon} alt="tick icon" className="pe-2" />
                Mark as Complete
              </Button>
            </div>
          )}
          <div className="mt-3 table-responsive">
            <PrimaryTable
              columns={columns}
              data={data}
              responsive
              bordered={false}
              keyField="id"
              selectRow={selectRow}
              rowUrl={"/employees/my-task"}
              isPermission={true}
            />
            {tasksData?.length > 0 && pageCount > 1 && (
              <div>
                <PrimaryPagination
                  pageCount={pageCount}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
export default TaskList;
