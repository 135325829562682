import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEmailDetail } from "services/DMCAEmailsServices";
import TopHeading from "components/Common/TopHeading";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { emailAction } from "services/DMCAEmailsServices";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { useNavigate } from "react-router-dom";
import { setPageTitle } from "utils/pageTitle";
import { useSelector } from "react-redux";

function EmailDetail() {
  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);

  useEffect(() => {
    setPageTitle("DMCA - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getEmailDetail(id)
      .then(res => {
        setEmailData(res?.data);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const handleAction = status => {
    const payload = {
      id,
      status,
    };
    setLoading(true);
    emailAction(payload)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
        navigate("/others/dmca-emails");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };
  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <TopHeading heading={"DMCA"} />
      <Row>
        <Col sm="9">
          <Card className="mt-4">
            <CardBody>
              <>
                <div className="user-info-section">
                  <div className="border-bottom pb-2">
                    <h5 className="text-15">User Info</h5>
                  </div>
                  <Row className="border-bottom text-13 py-3">
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        ID:
                      </div>
                      <div className="ms-4">{emailData?.id}</div>
                    </Col>
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Copyright:
                      </div>
                      <div
                        className="ms-4 product-title-container"
                        style={{ width: "300px" }}
                      >
                        {emailData?.copyright}
                      </div>
                    </Col>
                  </Row>
                  <Row className="border-bottom text-13 py-3">
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Status:
                      </div>
                      <div
                        className={`${
                          emailData?.status === "pending"
                            ? "warning-status"
                            : emailData?.status === "accept"
                            ? "success-status"
                            : emailData?.status === "reject"
                            ? "danger-status"
                            : emailData?.status === "counter"
                            ? "info-status"
                            : ""
                        } status w-max-content ms-4 text-capitalize`}
                      >
                        {emailData?.status}
                      </div>
                    </Col>
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Created At:
                      </div>
                      <div className="ms-4">{emailData?.created_at}</div>
                    </Col>
                  </Row>
                  {emailData?.action_by || emailData?.updated_at ? (
                    <Row className="border-bottom text-13 py-3">
                      <Col sm="6" className="d-flex align-items-center">
                        <div
                          className="font-semi-bold"
                          style={{ width: "30%" }}
                        >
                          Action by:
                        </div>
                        <div className="ms-4">{emailData?.action_by}</div>
                      </Col>
                      <Col sm="6" className="d-flex align-items-center">
                        <div
                          className="font-semi-bold"
                          style={{ width: "30%" }}
                        >
                          Updated at:
                        </div>
                        <div className="ms-4">{emailData?.updated_at}</div>
                      </Col>
                    </Row>
                  ) : null}
                </div>{" "}
                <div className="personal-detail-section">
                  <div className="border-bottom py-3">
                    <h5 className="text-15">Contact Person Details</h5>
                  </div>
                  <Row className="border-bottom text-13 py-3">
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        First Name:
                      </div>
                      <div className="ms-4">
                        {emailData?.contact_person_details?.first_name}
                      </div>
                    </Col>
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Last Name:
                      </div>
                      <div
                        className="ms-4 product-title-container"
                        style={{ width: "300px" }}
                      >
                        {emailData?.contact_person_details?.last_name}
                      </div>
                    </Col>
                  </Row>
                  <Row className="border-bottom text-13 py-3">
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Address:
                      </div>
                      <div className="ms-4">
                        {emailData?.contact_person_details?.address}
                      </div>
                    </Col>
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Company:
                      </div>
                      <div className="ms-4">
                        {emailData?.contact_person_details?.company}
                      </div>
                    </Col>
                  </Row>
                  <Row className="border-bottom text-13 py-3">
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Email:
                      </div>
                      <div className="ms-4">
                        {emailData?.contact_person_details?.email}
                      </div>
                    </Col>
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Telephone:
                      </div>
                      <div className="ms-4">
                        {emailData?.contact_person_details?.telephone}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="products-info-section">
                  <div className="border-bottom py-3">
                    <h5 className="text-15">Products</h5>
                  </div>
                  <div className="d-flex flex-wrap">
                    {emailData?.contact_person_data?.product_urls.map(
                      (productUrl, index) => (
                        <div
                          key={index}
                          className={`w-50 border-bottom text-13 py-3 d-flex align-items-center`}
                        >
                          <div
                            className="font-semi-bold"
                            style={{ width: "30%" }}
                          >
                            Product {index + 1}:
                          </div>
                          <a
                            target="_blank"
                            href={productUrl}
                            rel="noreferrer"
                            className="ms-4 primary-text product-title-container cursor-pointer"
                            style={{ width: "300px" }}
                          >
                            {productUrl}
                          </a>
                        </div>
                      )
                    )}
                  </div>
                  <Row className="border-bottom text-13 py-3">
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Description:
                      </div>
                      <div className="ms-4">
                        {emailData?.contact_person_data?.product_description}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="products-report-section">
                  <div className="border-bottom py-3">
                    <h5 className="text-15">Report Products</h5>
                  </div>
                  <div className="d-flex flex-wrap">
                    {emailData?.contact_person_report_data?.report_product_urls.map(
                      (productUrl, index) => (
                        <div
                          key={index}
                          className={`w-50 border-bottom text-13 py-3 d-flex align-items-center`}
                        >
                          <div
                            className="font-semi-bold"
                            style={{ width: "30%" }}
                          >
                            Product {index + 1}:
                          </div>
                          <a
                            target="_blank"
                            href={productUrl}
                            className="ms-4 primary-text product-title-container cursor-pointer"
                            style={{ width: "300px" }}
                            rel="noreferrer"
                          >
                            {productUrl}
                          </a>
                        </div>
                      )
                    )}
                  </div>
                  <Row className="border-bottom text-13 py-3">
                    <Col sm="6" className="d-flex align-items-center">
                      <div className="font-semi-bold" style={{ width: "30%" }}>
                        Description:
                      </div>
                      <div className="ms-4">
                        {
                          emailData?.contact_person_report_data
                            ?.report_product_description
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            </CardBody>
          </Card>
        </Col>
        {emailData?.status === "pending" && (
          <Col sm="3">
            {permissions?.includes("dmca-approve") ? (
              <Button
                className="w-100"
                onClick={() => handleAction(2)}
                color="primary"
                disabled={loading}
              >
                Approve
              </Button>
            ) : null}
            {permissions?.includes("dmca-reject") ? (
              <Button
                className="w-100 mt-4"
                onClick={() => handleAction(3)}
                color="primary"
                disabled={loading}
              >
                Reject
              </Button>
            ) : null}
            {permissions?.includes("dmca-counter") ? (
              <Button
                className="w-100 mt-4"
                onClick={() => handleAction(1)}
                color="primary"
                disabled={loading}
              >
                Counter
              </Button>
            ) : null}
          </Col>
        )}
      </Row>
    </div>
  );
}

export default EmailDetail;
