import React from "react";
import TopHeading from "components/Common/TopHeading";
import {
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import moment from "moment";
import DateRangePicker from "components/Common/DateRangePicker";
import PrimarySearch from "components/Common/PrimarySearch";
import optionIcon from "assets/images/options-icon.svg";
import editIcon from "assets/images/edit-icon-white.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getAllStoreList,
  storeRestrictions,
  deleteSellerStore,
  sellerStoreLogin,
  banSellersByStoreName,
} from "services/SellerStoreServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import DisableStorePopup from "components/Common/Popups/DisableStorePopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import loginIcon from "assets/images/login-icon.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import { useLocation } from "react-router-dom";
import { openLinkInNewTab } from "utils/linkHandling";
import useToggle from "hooks/useToggle";
import banStoreIcon from "assets/images/ban-btn-icon.svg";
import ResetButton from "components/Common/ResetButton";
import { userAgentLog } from "utils/userAgentLog";

function SellerStores() {
  const history = useNavigate();
  const location = useLocation();

  const [storesList, setStoresList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [search, setSearch] = useState(null);
  const [isDisabled, setIsDisabled] = useToggle(false);
  const [reason, setReason] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [isTemporaryBan, setIsTemporaryBan] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [banType, setBanType] = useState("");
  const [selectedStore, setSelectedStore] = useState();
  const [actionType, setActionType] = useState("");
  const [storeNames, setStoreNames] = useState();
  const [statusVal, setStatusVal] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const queryParams = new URLSearchParams(window.location.search);
  const paramsLength = Array.from(queryParams?.keys()).length;

  useEffect(() => {
    setPageTitle("Seller Stores - Inspire Uplift");
  }, []);

  useEffect(() => {
    const paramValue = queryParams.get("page") || undefined;
    const searchValue = queryParams.get("search") || undefined;
    const statusParam = queryParams?.get("status") || undefined;
    const startDateParam = queryParams?.get("start") || undefined;
    const endDateParam = queryParams.get("end") || undefined;
    setPageNo(paramValue || 1);
    setSearch(searchValue || "");
    let statusValue = "";
    if (statusParam) {
      switch (statusParam) {
        case "Temporary Ban":
          statusValue = "0";
          break;
        case "Banned":
          statusValue = 2;
          break;
        case "Deleted":
          statusValue = 3;
          break;
        case "Verified":
          statusValue = 4;
          break;
        case "Not Verified":
          statusValue = 5;
          break;
        default:
          statusValue = "";
      }
    }
    setStatusVal(statusValue);
    setDates([{
      startDate: startDateParam ? new Date(startDateParam) : new Date(),
      endDate: endDateParam ? new Date(endDateParam) : new Date(),
      key: "selection",
    }]);
    setLoading(true);
    getStores(
      paramValue,
      searchValue,
      statusValue,
      startDateParam,
      endDateParam
    );
  }, [location]);

  // This function handles the  seller store data

  const getStores = (page, search, status, start, end) => {
    getAllStoreList(page, search, status, start, end)
      .then(res => {
        setStoresList(res.data.sellers?.data);
        setPageCount(res?.data?.sellers?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  // This function handles the deletion of a seller store

  const handleDelete = () => {
    const startDate = moment(dates[0]?.startDate).format("YYYY-MM-DD");
    const endDate = moment(dates[0]?.endDate).format("YYYY-MM-DD");
    setLoading(true);
    deleteSellerStore(selectedStore)
      .then(res => {
        if (res.status === 200) {
          PrimaryToast({ type: "success", message: res?.data?.message });
          setSelectedStore("");
          setShowDeleteModal(!showDeleteModal);
          getStores(pageNo, search, statusVal, startDate, endDate);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      });
  };

  // This function handles the login of a seller store

  const handleStoreLogin = async (e, id) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const userData = await userAgentLog(); // Get user data using userAgentLog
      const userDataJsonString = JSON.stringify(userData);
      const res = await sellerStoreLogin(id, userDataJsonString);

      if (res.data.token) {
        if (window.location.hostname.includes("ods.inspireuplift.com")) {
          openLinkInNewTab("https://sellercentral.inspireuplift.com/dashboard");
        } else {
          openLinkInNewTab("https://sellercentral.itdeptiu.com/dashboard");
        }
      }
    } catch (err) {
      PrimaryToast({ type: "error", message: err?.response?.data?.message });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      formatter: (cell, row) => <div className="font-semi-bold">{row.id}</div>,
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "seller_group",
      text: "Seller Group",
      formatter: (cellContent, row) => {
        return <div className="w-max-content">{row?.seller_group}</div>;
      },
    },
    {
      dataField: "business_name",
      text: "Store Name",
    },
    {
      dataField: "is_active",
      text: "Status",
      formatter: (cellContent, row) => {
        let status;
        if (row.verify_seller === 0 && row.is_active === 1) {
          status = "Not Verified";
        }
        if (
          (row.verify_seller === 0 || row.verify_seller === 1) &&
          row.is_active === 0
        ) {
          status = "Temporary Ban";
        }
        if (
          (row.verify_seller === 0 || row.verify_seller === 1) &&
          row.is_active === 2
        ) {
          status = "Banned";
        }
        if (row.verify_seller === 1 && row.is_active === 1) {
          status = "Verified";
        }
        if (row.is_active === 3) {
          status = "Delete";
        }

        return (
          <span
            className={`${
              status === "Verified"
                ? "success-status"
                : status === "Banned" ||
                  status === "Temporary Ban" ||
                  status === "Delete"
                ? "danger-status"
                : status === "Not Verified"
                ? "warning-status"
                : ""
            } status w-max-content`}
          >
            {status}
          </span>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cell, row) => <div>{row?.created_at}</div>,
    },
    {
      dataField: "last_login",
      text: "Last Login",
      formatter: (cell, row) => <div>{row?.last_login}</div>,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) =>
        permissions?.includes("store-list") ? (
          <div className="d-flex align-items-center">
            {permissions?.includes("store-edit") && (
              <div
                className="action-btn edit-icon-bg me-2 cursor-pointer"
                onClick={() => history(`/seller/stores/edit/${row.id}`)}
              >
                <img src={editIcon} className="" alt="edit icon" />
              </div>
            )}

            <div>
              <div
                className="action-btn edit-icon-bg cursor-pointer"
                onClick={e => handleStoreLogin(e, row.id)}
                id={`tooltip-${row.id}`}
              >
                <img src={loginIcon} className="" alt="login icon" />
              </div>
              <UncontrolledTooltip
                target={`tooltip-${row.id}`}
                placement="bottom"
              >
                Login
              </UncontrolledTooltip>
            </div>

            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                // to="#"
                className="card-drop px-3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={optionIcon} alt="rectangeArrow" />
              </DropdownToggle>
              <DropdownMenu
                className={`dropdown-menu-end   ${
                  storesList?.length === 0 && "single-list-dropdown"
                }`}
              >
                {((row.verify_seller === 0 || row.verify_seller === 1) &&
                  row.is_active === 0) ||
                ((row.verify_seller === 0 || row.verify_seller === 1) &&
                  row.is_active === 2) ? (
                  <DropdownItem
                    onClick={e => {
                      handleEnable(e, row.id);
                    }}
                  >
                    Unban
                  </DropdownItem>
                ) : permissions?.includes("store-delete") &&
                  row.is_active === 1 ? (
                  <DropdownItem
                    onClick={e => {
                      handleAction(e, row.id);
                    }}
                  >
                    Ban
                  </DropdownItem>
                ) : null}
                {permissions?.includes("store-delete") &&
                  row?.is_active == 0 && (
                    <DropdownItem
                      onClick={e => {
                        handleAction(e, row.id, "temporary");
                      }}
                    >
                      Ban
                    </DropdownItem>
                  )}
                <DropdownItem
                  onClick={() => {
                    history(`/seller/stores/comments/${row.id}`);
                  }}
                >
                  Comment
                </DropdownItem>

                <DropdownItem
                  onClick={() =>
                    openLinkInNewTab(`/seller/stores/logs/${row.id}`, "_blank")
                  }
                >
                  Logs
                </DropdownItem>
                {row.is_active !== 3 &&
                  permissions?.includes("store-delete") && (
                    <DropdownItem
                      onClick={() => {
                        setSelectedStore(row.id);
                        setShowDeleteModal(!showDeleteModal);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ) : null,
    },
  ];

  // This function handles the restrictions of a seller store

  const handleStoreRestrictions = (id, status, reason) => {
    toast.dismiss();
    setLoading(true);
    const startDate = moment(dates[0]?.startDate).format("YYYY-MM-DD");
    const endDate = moment(dates[0]?.endDate).format("YYYY-MM-DD");
    if (
      actionType == "banByStoreName" ||
      actionType == "banByCheckboxSelection"
    ) {
      let payload = {
        ban_status: status,
        reason: reason,
      };
      if (actionType == "banByStoreName") {
        payload = {
          ...payload,
          store_names: storeNames,
        };
      } else if (actionType == "banByCheckboxSelection") {
        const stores = storesList
          ?.filter(store => selectedRowKeys?.includes(store.id))
          ?.map(store => store.business_name);
        payload = {
          ...payload,
          store_names: stores.join(","),
        };
      }
      banSellersByStoreName(payload)
        .then(res => {
          setIsDisabled(false);
          setBanType("");
          setStoreNames("");
          setReason("");
          setActionType("");
          setIsTemporaryBan();
          getStores(pageNo, search, statusVal, startDate, endDate);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        });
    } else {
      storeRestrictions(id, status, reason)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          if (status !== 1) {
            setIsDisabled(false);
            setSelectedId("");
            setIsTemporaryBan();
          }
          setBanType("");
          getStores(pageNo, search, statusVal, startDate, endDate);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          PrimaryToast({
            type: "error",
            message: err?.response?.data?.message,
          });
        });
    }
  };

  // This function handles the disable of a seller

  const handleDisable = () => {
    let status;
    if (banType !== "temporary") {
      switch (isTemporaryBan) {
        case true:
          status = 2;
          break;
        case false:
          status = 0;
          break;
        default:
          status = 0;
      }
    } else {
      status = 2;
    }
    handleStoreRestrictions(selectedId, status, reason);
  };

  const handleAction = (e, id, type) => {
    setIsDisabled(true);
    setSelectedId(id);
    setBanType(type);
    setActionType("banById");
  };

  const handleEnable = (e, id) => {
    e.stopPropagation();
    handleStoreRestrictions(id, 1);
  };

  const data = storesList?.map((item, index) => {
    return {
      ...item,
    };
  });

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (search) {
        queryParams.set("search", search);
      }
      if (!search && queryParams.get("search")) {
        queryParams.delete("search");
      }
      if (queryParams.get("page")) {
        queryParams.delete("page");
      }
      if (statusVal) {
        const statusValue = checkStatus();
        queryParams?.set("status", statusValue);
        queryParams.set(
          "start",
          moment(dates[0]?.startDate).format("YYYY-MM-DD")
        );
        queryParams.set("end", moment(dates[0]?.endDate).format("YYYY-MM-DD"));
      } else {
        queryParams?.delete("status");
        queryParams.delete("start");
        queryParams.delete("end");
      }

      history(`?${queryParams.toString()}`);
    }
  };

  const checkStatus = () => {
    let statusParam;
    switch (Number(statusVal)) {
      case 0:
        statusParam = "Temporary Ban";
        break;
      case 2:
        statusParam = "Banned";
        break;
      case 3:
        statusParam = "Deleted";
        break;
      case 4:
        statusParam = "Verified";
        break;
      case 5:
        statusParam = "Not Verified";
        break;
      default:
        statusParam = "";
    }
    return statusParam;
  };

  const handleApplyFilter = () => {
    if (search) {
      queryParams.set("search", search);
    }
    if (!search && queryParams.get("search")) {
      queryParams.delete("search");
    }
    if (queryParams.get("page")) {
      queryParams.delete("page");
    }
    if (statusVal) {
      const statusValue = checkStatus();
      queryParams?.set("status", statusValue);
      queryParams.set(
        "start",
        moment(dates[0]?.startDate).format("YYYY-MM-DD")
      );
      queryParams.set("end", moment(dates[0]?.endDate).format("YYYY-MM-DD"));
    } else {
      queryParams?.delete("status");
      queryParams.delete("start");
      queryParams.delete("end");
    }

    history(`?${queryParams.toString()}`);
  };

  const handleOnSelectAll = (isSelected, rows, e) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.id));
    } else {
      setSelectedRowKeys([]);
    }
  };

  let nonSelected = storesList?.map(item => {
    if (
      item?.is_active == 3 ||
      ((item.verify_seller === 0 || item.verify_seller === 1) &&
        item.is_active === 2)
    ) {
      return item.id;
    }
  });

  const selectRow = {
    mode: "checkbox",
    nonSelectableClasses: "hide-checkbox",
    nonSelectable: nonSelected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, row.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
      }
    },
    onSelectAll: handleOnSelectAll,
    selected: selectedRowKeys,
  };

  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure you want to delete this Seller?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedStore("");
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Seller Stores"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <PrimarySearch
                value={search}
                placeholder={"Search by Name, Email, Store Name"}
                handleKeyPress={handleKeyPress}
                handleChange={handleSearch}
              />
              <select
                value={statusVal}
                onChange={e => setStatusVal(e.target.value)}
                className="primary-select-option ms-2"
              >
                <option value={""}>Search By Status</option>
                <option value={"0"}>Temporary Ban</option>
                <option value={2}>Banned</option>
                <option value={3}>Deleted</option>
                <option value={4}>Verified</option>
                <option value={5}>Not Verified</option>
              </select>
              {statusVal && (
                <div className="position-relative ms-1">
                  <div
                    className="form-control cursor-pointer rounded-full"
                    style={{ color: "#74788D", width: "max-content" }}
                    onClick={() => setShowCalendar(true)}
                  >
                    {moment(dates[0]?.startDate)?.format("DD MMM YYYY")} -{" "}
                    {moment(dates[0]?.endDate)?.format("DD MMM YYYY")}
                  </div>

                  {showCalendar && (
                    <>
                      <div
                        className="position-fixed top-0 start-0 w-100 h-100"
                        style={{ zIndex: "10" }}
                        onClick={() => setShowCalendar(false)}
                      ></div>
                      <DateRangePicker
                        dates={dates}
                        handleDatePicker={handleDatePicker}
                        handleSubmit={() => setShowCalendar(false)}
                        // minDate={new Date()}
                        maxDate={new Date()}
                      />
                    </>
                  )}
                </div>
              )}
              <Button
                color="primary"
                className={`btn-rounded  ms-2`}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
              <div className="d-flex align-items-center">
                {paramsLength > 0 && <ResetButton />}
              </div>
            </div>

            {permissions?.includes("multi-store-ban") ? (
              <Button
                color="danger"
                className="rounded-full d-flex align-items-center"
                onClick={() => {
                  setIsDisabled(true);
                  if (selectedRowKeys?.length == 0) {
                    setActionType("banByStoreName");
                  } else {
                    setActionType("banByCheckboxSelection");
                  }
                }}
              >
                <img
                  src={banStoreIcon}
                  className="me-2"
                  alt="bulk ban sellers"
                />
                Bulk Ban Sellers
              </Button>
            ) : null}
          </div>
          <div className="mt-3 table-responsive" style={{ minHeight: "50vh" }}>
            <PrimaryTable
              columns={columns}
              data={data}
              responsive
              bordered={false}
              keyField="id"
              selectRow={selectRow}
              path="seller-stores"
            />
            {storesList?.length > 0 && pageCount > 1 && (
              <PrimaryPagination
                pageCount={pageCount}
                setPageNo={setPageNo}
                pageNo={pageNo}
              />
            )}
          </div>
          {isDisabled && (
            <DisableStorePopup
              handleClick={() => {
                setSelectedId("");
                setIsDisabled(!isDisabled);
                setBanType("");
                setActionType("");
                setIsTemporaryBan();
                setStoreNames("");
                setSelectedRowKeys([]);
                setReason("");
              }}
              loading={loading}
              isOpen={isDisabled}
              isScrollable={false}
              className={"primary-popup-container"}
              heading={
                actionType == "banByStoreName"
                  ? "Bulk Ban Sellers"
                  : "Seller Ban"
              }
              setReason={setReason}
              handleDisable={handleDisable}
              setIsTemporaryBan={setIsTemporaryBan}
              banType={banType}
              actionType={actionType}
              setStoreNames={setStoreNames}
              storeNames={storeNames}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default SellerStores;
