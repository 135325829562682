import React, { useEffect, useState } from "react";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import { useSearchParams } from "react-router-dom";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import PrimarySelect from "components/Common/PrimarySelect";
import { countOptions } from "utils/constant";
import { getStoreStats } from "services/ReportsServices";
import downwradIcon from "assets/images/downword-white.svg";
import ResetButton from "components/Common/ResetButton";

const ProductStore = () => {
  const [urlParams, setUrlParams] = useSearchParams();
  const [pageNo, setPageNo] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [filters, setFilters] = useState({
    selected_row: "",
    count_sign: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setPageTitle("Product Views and Purchases Store - Inspire Uplift");
  }, []);

  useEffect(() => {
    const pageParam = urlParams?.get("page") || undefined;
    const countParam = urlParams?.get("q_value") || undefined;
    const selectedRowParam = urlParams?.get("selected_row") || undefined;
    const countSignParam = urlParams?.get("count_sign") || undefined;
    setPageNo(pageParam || 1);
    setFilters({
      ...filters,
      selected_row: selectedRowParam,
      count_sign: countSignParam,
      q_value: countParam,
    });
    setLoading(true);
    getStoreStats(pageParam, countParam, selectedRowParam, countSignParam)
      .then(res => {
        console.log(res);
        setProductData(res?.data);
        setPageCount(res?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [urlParams]);


  const columns = [
    {
      dataField: "store_name",
      text: "Store Name",
      formatter: (cell, row) => (
        <div className="product-title-container">{row.store_name}</div>
      ),
      headerStyle: { width: "35%" },
    },
    {
        dataField: "orders_count",
        text: "Total Sale",
        formatter: (cell, row) => {
          return <div>{parseFloat(row.orders_count?.toFixed(2))}</div>;
        },
      },
    {
      dataField: "refund_rate",
      text: "Refund Rate",
      formatter: (cell, row) => <div>{parseFloat(row.refund_rate?.toFixed(2))}</div>,
      headerFormatter: column => (
        <div className="cursor-pointer">Refund Rate </div>
      ),
    },
    {
      dataField: "review_rate",
      text: "Review Rate",
      formatter: (cell, row) => <div>{parseFloat(row.review_rate?.toFixed(2))}</div>,
      headerFormatter: column => (
        <div className="cursor-pointer">Review Rate </div>
      ),
    },
    {
      dataField: "dispute_rate",
      text: "Dispute Rate",
      formatter: (cell, row) => {
        return <div>{parseFloat(row.dispute_rate?.toFixed(2))}</div>;
      },
    },
  ];

  const data = productData?.map(item => {
    return {
      ...item,
    };
  });

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters(pre => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleApply = e => {
    const existingParams = Object.fromEntries(urlParams?.entries());
    const currentParams = Object.entries(filters);
    if (filters?.count_sign) {
      existingParams["count_sign"] = filters?.count_sign;
    } else {
      delete existingParams["count_sign"];
    }

    if (filters?.selected_row) {
      existingParams["selected_row"] = filters?.selected_row;
    } else {
      delete existingParams["selected_row"];
    }
    if (filters?.q_value) {
      existingParams["q_value"] = filters?.q_value;
    } else {
      delete existingParams["q_value"];
    }

    if (existingParams?.hasOwnProperty("page")) {
      delete existingParams["page"];
    }

    setUrlParams(existingParams);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-4">
        <TopHeading heading={"Store Stats"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
            <PrimarySelect
              options={[
                  { value: "", name: "Please select column" },
                  { value: "orders_count", name: "Total Sale" },
                { value: "refund_rate", name: "Refund Rate" },
                { value: "review_rate", name: "Review Rate" },
                { value: "dispute_rate", name: "Dispute Rate" },
              ]}
              handleChange={handleChange} // Select filter field
              classname={"me-3"}
              name="selected_row"
              value={filters?.selected_row || ""}
            />
            <div className="input-group rounded-full w-240 me-3">
              <input
                type="text"
                className="form-control"
                placeholder="By Price"
                name="q_value"
                value={filters?.q_value || ""}
                onChange={handleChange}
              />
              {filters?.count_sign && (
                <button type="button" className="btn btn-primary">
                  {filters?.count_sign == "equal"
                    ? "="
                    : filters?.count_sign == "greater"
                    ? ">"
                    : filters?.count_sign == "smaller"
                    ? "<"
                    : ""}
                </button>
              )}

              <button
                type="button"
                className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                onClick={toggleOpen}
              >
                <img src={downwradIcon} alt="downword icon" />
              </button>
              <ul
                className={`dropdown-menu dropdown-menu-end  ${
                  isOpen && "show"
                }`}
              >
                {countOptions.map((option, index) => {
                  return (
                    <li
                      className=""
                      key={index}
                      onClick={() => {
                        setFilters(pre => {
                          return {
                            ...pre,
                            count_sign: option.value,
                          };
                        });
                        toggleOpen();
                      }}
                    >
                      <span className="dropdown-item fs-6 fw-bold">
                        {option.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <Button
              color="primary"
              className={`btn-rounded  me-2`}
              onClick={handleApply}
            >
              Apply Filter
            </Button>
            <div className="d-flex align-items-center">
              {urlParams?.size > 0 && <ResetButton />}
            </div>
          </div>

          <div className="table-responsive mt-4">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="order_number"
            />
          </div>
          {data?.length > 0 && pageCount > 1 ? (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductStore;
