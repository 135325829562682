import React, { useState, useEffect } from "react";
import moment from "moment";
import TopHeading from "components/Common/TopHeading";
import DateFilter from "components/Common/DateFilter";
import { CardBody, Card } from "reactstrap";
import { getSalesBySeller } from "services/ReportsServices";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrograssPopup from "components/Common/Popups/ProgressPopup";
import { setPageTitle } from "utils/pageTitle";
import PrimaryTable from "components/Common/PrimaryTable";
import viewIcon from "assets/images/rectangle-arrow.svg";
import { openLinkInNewTab } from "utils/linkHandling";

function SalesBySeller() {
  const [sellerSalesData, setSellerSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [dates, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  useEffect(() => {
    setPageTitle("Sales By Seller - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSalesBySeller(pageNo, dates?.startDate, dates?.endDate)
      .then(res => {
        setSellerSalesData(res.data.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [pageNo, dates?.startDate, dates?.endDate]);

  const columns = [
    {
      dataField: "business_name",
      text: "Store name",
    },
    {
      dataField: "quantity",
      text: "Orders",
    },
    {
      dataField: "discount",
      text: "Discount",
      formatter: (cell, row) => <div>{row?.discount?.toFixed(2)}</div>,
    },
    {
      dataField: "subtotal",
      text: "Subtotal",
      formatter: (cell, row) => <div>{row?.subtotal?.toFixed(2)}</div>,
    },
    {
      dataField: "tax",
      text: "Tax",
      formatter: (cell, row) => <div>{row?.tax?.toFixed(2)}</div>,
    },
    {
      dataField: "grand_total",
      text: "Grand Total",
      formatter: (cell, row) => <div>{row?.grand_total?.toFixed(2)}</div>,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div
          className="action-btn edit-icon-bg cursor-pointer"
          onClick={() => handleView(row.seller_id)}
        >
          <img src={viewIcon} height={18} width={18} alt="view icon" />
        </div>
      ),
    },
  ];

  const handleView = id => {
    const url = `${
      window?.location?.origin
    }/reports/sales-by-seller/${id}?start=${moment(dates?.startDate).format(
      "YYYY-MM-DD"
    )}&end=${moment(dates?.endDate).format("YYYY-MM-DD")}`;
    console.log(dates,url);
    openLinkInNewTab(url, "_blank");
  };
  
  const data = sellerSalesData?.map(item => {
    return {
      ...item,
    };
  });

  return (
    <div className="page-content reports-page-container">
      {loading && <PrograssPopup label={"Loading..."} />}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Sales By Seller"} />
        <DateFilter dates={dates} setDate={setDate} />
      </div>
      <Card>
        <CardBody>
          <div className="">
            <PrimaryTable
              columns={columns}
              data={data}
              classes={"table align-middle table-nowrap"}
              headerWrapperClasses={"thead-light"}
              responsive
              bordered={false}
              keyField="seller_id"
            />
          </div>
        </CardBody>
        {sellerSalesData?.length > 0 && pageCount > 1 && (
          <PrimaryPagination
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
          />
        )}
      </Card>
    </div>
  );
}

export default SalesBySeller;
